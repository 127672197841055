import React, { useEffect, useState } from "react";
import { getFeeStructureByClassAPI } from "../../apis/feesAPIs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { set_fee_structures } from "../../redux/reducers/school/actions";

export default function FeeStructureTable(props) {
  const location = useLocation();
  const [classFeeStructure, setClassFeeStructure] = useState([]);
  const feeStructures = useSelector((s) => s.schoolData.feeStructures);

  useEffect(() => {
    getClassFeeStructure(props.cls);
  }, [feeStructures]);

  const getClassFeeStructure = async (cls) => {
    const fs = feeStructures.filter((fs) => fs.class === cls);
    setClassFeeStructure(fs);
  };

  const handleDeleteFee = (fee_id) => {
    props.openModal("delete", {
      type: "fee-structure",
      id: fee_id,
      redirect: "fee-settings",
    });
  };

  const handleEditFeeStructure = (fee_id) => {
    props.openModal("editFeeStructure", {
      id: fee_id,
      redirect: "fee-settings",
    });
  };

  return (
    <React.Fragment>
      <table className="table table-hover table-sm">
        <thead className="thead">
          <tr>
            <th></th>
            <th class="text-left">Fee type</th>
            <th className="text-right">Amount</th>
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {classFeeStructure.length ? (
            classFeeStructure.map((fee, i) => (
              <tr key={"fee_s_" + i}>
                <td>
                  <div
                    style={{
                      background: "green",
                      borderRadius: 5,
                      height: 5,
                      width: 5,
                      marginTop: 6,
                    }}
                  ></div>
                </td>
                <td>{fee.fee_type}</td>
                <td className="text-right">{fee.fee_amount}</td>
                <td className="text-right" style={{ fontSize: 10 }}>
                  <a
                    href="#"
                    className="text-success"
                    onClick={() => handleEditFeeStructure(fee.id)}
                  >
                    <i className="fa fa-pencil mt-0"></i> <strong>Edit</strong>
                  </a>
                  <a
                    href="#"
                    className="text-danger "
                    style={{ marginLeft: 10 }}
                    onClick={() => handleDeleteFee(fee.id)}
                  >
                    <i className="fa fa-remove mt-0"></i>{" "}
                    <strong>Delete</strong>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>No data Available</p>
                  <a
                    href="#"
                    className="text-success"
                    onClick={() => props.openModal("newFeeStructure")}
                  >
                    <i className="fa fa-plus"></i> <strong>Create</strong>
                  </a>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}
