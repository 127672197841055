import React, { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { feeSearchAPI } from "../apis/feesAPIs";
import { LoaderCircle } from "../components/common/loaderCircle";
import LoaderStandAlone from "../components/common/loaderStandAlone";
import {
  init_fee_deposit,
  set_data_loaded,
  set_fee_history,
  set_student,
} from "../redux/reducers/fees/actions";
import { set_recent_student } from "../redux/reducers/warehouse/actions";

export default function FeeDepositeStudentSearchView() {
  const navigate = useNavigate();
  const moreOptions = useRef(null);
  const dispatch = useDispatch();

  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  const student = useSelector((state) => state.feesDepositePage.student);
  const dataLoaded = useSelector((state) => state.feesDepositePage.dataLoaded);
  const recentStudents = useSelector(
    (state) => state.warehouse.recentSearchedStudents
  );
  const studentFeeHistory = useSelector(
    (state) => state.feesDepositePage.studentFeeHistory
  );

  const initDepositFees = useSelector(
    (s) => s.feesDepositePage.initDepositFees
  );

  useEffect(() => {
    const feeFilter = {
      s_id: student.s_id,
      fee_type: "Tuition Fee",
      academic_session: schoolInfo.academic_session.academic_session,
    };

    handleFilterData(feeFilter);
    const rs = [];
    const st = recentStudents.filter((s) => s.s_id == student.s_id);
    if (!st.length) {
      rs.push(student);
    }
    dispatch(set_recent_student([...rs, ...recentStudents]));
    //reset added fees
    if (initDepositFees && initDepositFees.length) {
      dispatch(init_fee_deposit([]));
    }
  }, []);

  const handleFilterData = (filter) => {
    document.getElementById("fee_type").innerText =
      "Showing Payment history for " + filter.fee_type;
    feeSearchAPI(filter)
      .then((data) => {
        if (data.status) {
          const feeHistory = [];
          for (const f in data.data) {
            feeHistory.push(data.data[f]);
          }
          console.log(feeHistory);
          dispatch(set_fee_history(feeHistory));
          // dispatch(set_student(student));
          setTimeout(() => {
            dispatch(set_data_loaded(true));
          }, 500);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterByFeeType = (e) => {
    dispatch(set_data_loaded(false));
    if (e.target.value != "none") {
      const feeFilter = {
        s_id: student.s_id,
        fee_type: e.target.value,
        academic_session: schoolInfo.academic_session.academic_session,
      };
      handleFilterData(feeFilter);
    } else {
      return;
    }
  };

  const openFeeDepositer = () => {
    navigate({
      pathname: "/app/collectFeeView",
      search: createSearchParams({
        referrer: "fee-deposite-search",
        s_id: student.s_id,
        tNode: Date.now(),
      }).toString(),
    });
  };
  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row">
          <div className="col-md-10">
            <div className="card card-cascade narrower">
              <div
                className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower py-2 mx-4  d-flex justify-content-between align-items-center"
                style={{ width: "20%" }}
              >
                <a href="" className="white-text mx-3">
                  Student Details
                </a>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th>Admi. No</th>
                        <th>Student Name</th>
                        <th>Father's Name</th>
                        <th>Mother's Name</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Roll No</th>
                      </tr>
                    </thead>
                    <tbody className="sdetail">
                      <tr>
                        <td>{student.s_admission}</td>
                        <td className="capi">{student.s_name}</td>
                        <td className="capi">{student.sf_name}</td>
                        <td className="capi">{student.sm_name}</td>
                        <td className="text-center">{student.s_class}</td>
                        <td className="text-center">{student.s_section}</td>
                        <td className="text-center">{student.s_roll}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-success waves-effect waves-light"
              >
                More
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger dropdown-toggle px-3 waves-effect waves-light"
                onMouseEnter={() =>
                  (moreOptions.current.style.display = "block")
                }
              >
                {" "}
              </button>
              <center>
                <div
                  className="dropdown-menu dropDwnFix"
                  ref={moreOptions}
                  onMouseLeave={() =>
                    (moreOptions.current.style.display = "none")
                  }
                >
                  <a
                    href="https://quantumfms.ranchitechnopark.com/index.php/dashboard/all_details/31"
                    className="dropdown-item more"
                  >
                    <i className="fa fa-address-book" aria-hidden="true"></i>{" "}
                    Details
                  </a>
                  <Link
                    to={`/app/student-payments?referrer=fee-deposit&s_id=${student.s_id}`}
                    className="dropdown-item"
                  >
                    <i className="fa fa-address-book" aria-hidden="true"></i>{" "}
                    Payments history
                  </Link>
                </div>
              </center>
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="card-cascade narrower">
              <div className="card-body">
                <p>Student Payment Summary</p>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-due_home"
                    role="tabpanel"
                    aria-labelledby="nav-due_home-tab"
                  >
                    <br />
                    <h5 style={{ fontSize: 12, color: "gray", marginTop: -20 }}>
                      Check Previous Dues If Any*
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "30%" }}>
                        <form>
                          <div className="form-group">
                            <select
                              name="feetype"
                              className="form-control form-control-sm"
                              onChange={(e) => filterByFeeType(e)}
                            >
                              <option selected value={"none"}>
                                Select
                              </option>
                              {schoolInfo.hasOwnProperty("school_fees")
                                ? schoolInfo.school_fees.map((fees, i) => (
                                    <React.Fragment key={"opt_" + i}>
                                      <option value={fees.fee_types}>
                                        <td>{fees.fee_types}</td>
                                      </option>
                                    </React.Fragment>
                                  ))
                                : null}
                            </select>
                          </div>
                        </form>
                      </div>
                      <div>
                        <button
                          onClick={() => openFeeDepositer()}
                          className="btn btn-sm btn-success waves-effect waves-light cfc3"
                          style={{ borderRadius: 20 }}
                        >
                          Collect New Fee
                        </button>
                      </div>
                    </div>

                    <div className="row" style={{ minHeight: 150 }}>
                      <div className="col-md-12">
                        <p id="fee_type" style={{ fontSize: 13 }}></p>
                        {dataLoaded ? (
                          <div
                            className="table-responsive"
                            style={{ paddinTop: 50 }}
                          >
                            <table className="table table-bordered table-sm">
                              <thead className="thead">
                                <tr>
                                  {studentFeeHistory.map((history) => (
                                    <th
                                      className="text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {String(history.month).substring(0, 3)}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="sdetail">
                                <tr>
                                  {studentFeeHistory.map((history) => (
                                    <td className="text-center">
                                      {history.fee_amount}
                                    </td>
                                  ))}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <LoaderStandAlone />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
