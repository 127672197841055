import React, { useEffect, useRef } from "react";
import { APP_DATA } from "../../app-data";
import { saveNewFeeCategoryAPI } from "../../apis/feesAPIs";
import { notify } from "../utils";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function AddNewFeeType(props) {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const formData = useRef(structuredClone({ fee_types: "" }));
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);

  const handleForm = (e) => {
    if (e.target.value) {
      formData.current[e.target.id] = e.target.value;
      document.getElementById("err_" + e.target.id).innerText = "";
    }
  };

  const handleSaveFeeType = async () => {
    //check if the fee type is already present
    const ft = schoolInfo.school_fees.find(
      (fees) => fees.fee_types === formData.current["fee_types"]
    );
    if (ft) {
      notify("error", "This fee category is already present");
      return;
    }

    let finalValidation = 1;
    for (const f in formData.current) {
      if (formData.current[f] === undefined || formData.current[f] === "") {
        finalValidation &= 0;
        document.getElementById("err_" + f).innerText =
          APP_DATA.errorMessage.requiredField;
      } else {
        document.getElementById("err_" + f).innerText = "";
      }
    }
    if (finalValidation) {
      const newFee = await saveNewFeeCategoryAPI(formData.current);
      if (newFee.status) {
        navigate({
          pathname: "/app/fee-settings",
          search: createSearchParams({
            referrer: "create-new-category",
            tNode: Date.now(),
          }).toString(),
        });
        notify("success", "New fee category has been created!");
        formData.current = structuredClone(formData.current);
        //finally
        props.functions.closeModal();
      } else {
        notify("error", "Something went wrong! Please try again");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="" style={{ fontSize: 16 }}>
            Add a new fee category
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => props.functions.closeModal()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="form-group" style={{ width: "70%" }}>
                <label className="fml" for="csname">
                  Fee category
                </label>
                <input
                  id="fee_types"
                  type="text"
                  name="fee_types"
                  onChange={(e) => handleForm(e)}
                  className="form-control form-control-sm"
                />
                <span class="errMessage" id="err_fee_types"></span>
              </div>
              <div className="form-group" style={{ width: "27%" }}>
                <label className="fml" for="csname">
                  Status
                </label>
                <input
                  readOnly={true}
                  id="fee_types"
                  type="text"
                  name="fee_types"
                  onChange={(e) => handleForm(e)}
                  className="form-control form-control-sm"
                  placeholder="Enabled"
                />
                <span class="errMessage" id="err_fee_types"></span>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => handleSaveFeeType()}
            className="btn btn-sm btn-success mb-2 waves-effect waves-light"
          >
            save
          </button>
          <button
            type="button"
            onClick={() => props.functions.closeModal()}
            className="btn btn-sm btn-danger mb-2 waves-effect waves-light"
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
