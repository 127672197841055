import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  schoolInfoAPI,
  setDefaultAcademicAPI,
  updateSchoolAPI,
} from "../apis/schoolAPIs";
import { APP_DATA } from "../app-data";
import { notify } from "../components/utils";
import {
  set_data_loaded,
  set_default_academic,
  set_school_info,
} from "../redux/reducers/school/actions";

export default function SchoolSettingsView() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const schoolInfo = useSelector((state) => state.schoolData.schoolInfo);
  const moreOptions1 = useRef(null);
  const validationState = useRef(0);
  const formData = useRef(Object.assign({}));

  useEffect(() => {
    getSchoolInfo();
  }, [location.search]);

  const formChangeHandler = async (event) => {
    // const validationResponse = await inputFieldValidator(event)
    const validationResponse = {
      valid: true,
      error_message: "input is not valid",
    };
    if (validationResponse.valid) {
      validationState.current = 1;
      formData.current[event.target.name] = event.target.value;
      document.getElementById("err_" + event.target.name).innerText = "";
    } else {
      validationState.current = 0;
      document.getElementById("err_" + event.target.name).innerText =
        validationResponse["error_message"];
    }
    console.log(formData.current);
  };

  const handleSetDefaultAcademic = (e) => {
    const payload = {
      academic_session_id: String(e.target.id).split("_")[1],
    };
    setDefaultAcademicAPI(payload)
      .then((update) => {
        if (update.data) {
          navigate({
            pathname: "/app/school-settings",
            search: createSearchParams({
              referrer: "update-academic-session",
              tNode: Date.now(),
            }).toString(),
          });
          notify("success", "Academic session updated");
        } else {
          notify("error", "Unable to process");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSchoolInfo = () => {
    schoolInfoAPI()
      .then((school) => {
        if (school.status) {
          dispatch(set_school_info(school.data));
          dispatch(set_data_loaded(true));
        } else {
          dispatch(set_data_loaded(true));
        }
      })
      .catch((e) => {
        console.log(e);
        notify("error", "Something went wrong");
      });
  };

  const handleSchoolUpdate = async (e) => {
    if (validationState.current) {
      let formattedData = {};
      for (const t in schoolInfo.basic) {
        if (
          formData.current.hasOwnProperty(t) &&
          schoolInfo.basic[t] !== formData.current[t]
        ) {
          formattedData[t] =
            formData.current[t] === undefined ? null : formData.current[t];
        } else {
          formattedData[t] = schoolInfo.basic[t];
        }
      }
      console.log(formattedData);
      const updated = await updateSchoolAPI(formattedData);
      console.log("update", updated);
      navigate({
        pathname: "/app/school-settings",
        search: createSearchParams({
          referrer: "update-school-info",
          tNode: Date.now(),
        }).toString(),
      });
      notify("success", "School info updated");
      formData.current = Object.assign({}, APP_DATA.admissionForm.allFields);
    } else {
      notify("error", "No changes found");
    }
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="row" style={{ height: 500 }}>
          <div className="col-md-7 mt-5">
            <div className="card-cascade narrower">
              <div
                className="view view-cascade gradient-card-header p-1 z-depth-2 topm rounded narrower py-0 mx-4  d-flex justify-content-between align-items-center"
                style={{ width: "30%" }}
              >
                <div className="white-text mx-3"><strong>School Information</strong></div>
              </div>
              <div
                className="card-body cardscroll fee_scroll"
                style={{ height: 490 }}
              >
                <form>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          className="form-control form-control-md form-hgt"
                          placeholder="Enter School Name"
                          name="name"
                          onChange={(e) => formChangeHandler(e)}
                          defaultValue={schoolInfo.basic.name}
                        />
                      </div>
                      <span className="errMessage" id="err_name"></span>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          className="form-control form-control-md form-hgt"
                          placeholder="Enter UDISE code"
                          name="udice_code"
                          onChange={(e) => formChangeHandler(e)}
                          defaultValue={schoolInfo.basic.udice_code}
                        />
                        <span className="errMessage" id="err_udice_code"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4 d-flex align-items-center">
                      <div className="form-outline datepicker w-100">
                        <input
                          type="text"
                          className="form-control form-control-md form-hgt"
                          placeholder="Enter email Address"
                          name="email"
                          onChange={(e) => formChangeHandler(e)}
                          defaultValue={schoolInfo.basic.email}
                        />
                        <span className="errMessage" id="err_email"></span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input
                          type="text"
                          placeholder="Enter Mobile No."
                          className="form-control form-control-md form-hgt"
                          name="mobile_no"
                          onChange={(e) => formChangeHandler(e)}
                          defaultValue={schoolInfo.basic.mobile_no}
                        />
                        <span className="errMessage" id="err_mobile_no"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4 pb-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-outline">
                            <input
                              type="text"
                              placeholder="Enter Telephone No."
                              className="form-control form-control-md form-hgt"
                              name="contact_no"
                              onChange={(e) => formChangeHandler(e)}
                              defaultValue={schoolInfo.basic.contact_no}
                            />
                            <span
                              className="errMessage"
                              id="err_contact_no"
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 pt-4 pb-2">
                          <select
                            onChange={(e) => formChangeHandler(e)}
                            name="district"
                            className="select form-control w-100 text-muted formBorder"
                            style={{ height: 30 }}
                          >
                            <option selected disabled>
                              {schoolInfo.basic.district}
                            </option>
                          </select>
                          <span className="errMessage" id="err_district"></span>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-12 pt-3">
                          <div className="form-outline">
                            <input
                              type="text"
                              placeholder="Pin code"
                              className="form-control form-control-md form-hgt"
                              name="pin_code"
                              onChange={(e) => formChangeHandler(e)}
                              defaultValue={schoolInfo.basic.pin_code}
                            />
                            <span
                              className="errMessage"
                              id="err_pin_code"
                            ></span>
                          </div>
                          <textarea
                            className="form-control mt-4"
                            placeholder="Enter Complete School Address"
                            rows="3"
                            name="address"
                            onChange={(e) => formChangeHandler(e)}
                            defaultValue={schoolInfo.basic.address}
                          ></textarea>
                          <span className="errMessage" id="err_address"></span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-3 pb-3">
                      <div className="row">
                        <div className="col-md-12 pb-2">
                          <select
                            onChange={(e) => formChangeHandler(e)}
                            name="state"
                            className="select form-control w-100 formBorder text-muted"
                            style={{ height: 30 }}
                          >
                            <option selected disabled>
                              {schoolInfo.basic.state}
                            </option>
                          </select>
                          <span className="errMessage" id="err_state"></span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="mb-4 d-flex justify-content-center">
                            <img
                              src="https://mdbootstrap.com/img/Photos/Others/placeholder.jpg"
                              alt="example placeholder"
                              style={{ width: 190, height: 75 }}
                            />
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="btn btn-warning btn-sm">
                              <label
                                className="form-label text-white m-0 p-0"
                                for="customFile1"
                              >
                                Choose logo
                              </label>
                              <input
                                type="file"
                                className="form-control d-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" pt-4 text-right">
                    {/* <input
                        className="btn btn-danger cfc3 btn-sm"
                        type="button"
                        value="Discard"
                      /> */}
                    <button
                      className="btn btn-primary cfc2 btn-sm"
                      type="button"
                      onClick={(e) => handleSchoolUpdate(e)}
                    >
                      Update Information
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-5 mt-5">
           {/* more on MouseEnter */}
          {/* <div
                onMouseEnter={() => moreOptions1.current.classList.add("show")}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: 50,
                  alignItems: "center",
                }}
              >
                <div className="dotdotcls" style={{ marginLeft: 5 }}></div>
                <div className="dotdotcls"></div>
                <div className="dotdotcls"></div>
              </div>

              <div
                className="dropdown-menu more feesettingTogle"
                x-placement="bottom-start"
                style={{
                  minWeight: "7rem",
                  padding: 0,
                  marginTop: 30,
                }}
                ref={moreOptions1}
                onMouseLeave={() =>
                  moreOptions1.current.classList.remove("show")
                }
              >
                <a className="dropdown-item" href="#" style={{ fontSize: 13 }}>
                  <i className="fa fa-plus mt-0"></i> Add new
                </a>

                <a className="dropdown-item" href="#" style={{ fontSize: 13 }}>
                  <i className="fa fa-question mt-0"></i> Help
                </a>
              </div> */}
               {/* close more on MouseEnter */}
            <div className=" card-cascade narrower">
              {/* more on MouseEnter */}
              
              {/* close more on MouseEnter */}
              <div className="card-body fee_scroll cardscroll">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <thead className="thead">
                      <tr>
                        <th>Academic Year</th>
                        <th className="text-center">Current Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{schoolInfo.academic_session.academic_session}</td>
                        <td className="text-center">Active</td>
                      </tr>
                    </tbody>
                    <thead className="thead">
                      <tr>
                        <th>#.</th>
                        <th>Academic Year</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schoolInfo.all_academics.map((academy, i) => (
                        <React.Fragment key={"academics_" + i}>
                          <tr>
                            <td>{i + 1}</td>
                            <td>{academy.academic_session}</td>
                            <td>
                              <button
                                id={academy.academic_session + "_" + academy.id}
                                disabled={
                                  academy.academic_session ===
                                  schoolInfo.academic_session.academic_session
                                }
                                className="btn btn-sm btn-danger ml-3 px-2 pt-2"
                                onClick={(e) => handleSetDefaultAcademic(e)}
                              >
                                set default
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
