import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { getStudentByidAPI } from "../apis/studentAPIs";
import { echo, notify } from "../components/utils";
import { set_student_data } from "../redux/reducers/students/actions";

export default function StudentDetailsView() {
  const location = useLocation();
  const navigate = useNavigate();

  const moreOptions = useRef(null);
  const urlSearch = new URLSearchParams(location.search);
  const studentList = useSelector(
    (state) => state.studentListPage.studentsList
  );

  const dispatch = useDispatch();
  const studentData = useSelector((state) => state.studentListPage.studentData);
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);

  useEffect(() => {
    async function getStudent() {
      const s_id = urlSearch.get("s_id");
      if (urlSearch.get("referrer") === "student-list") {
        dispatch(set_student_data(studentList.find((s) => s.s_id === s_id)));
      } else {
        const student = await getStudentByidAPI(s_id);
        if (student.status && student.data) {
          dispatch(set_student_data(student.data));
        } else {
          notify("error", "Something went wrong!");
        }
      }
    }
    getStudent();
  }, []);
  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row">
          <div className="col-md-10">
            <div className="card card-cascade narrower p-0">
            <div
                className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center"
                style={{ width: "25%" }}
              >
                <a href="" className="white-text mx-3">
                  Student Information
                </a>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th>Admi. No</th>
                        <th>Student Name</th>
                        <th>Father's Name</th>
                        <th>Mother's Name</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Roll No</th>
                      </tr>
                    </thead>
                    {studentData ? (
                      <tbody className="sdetail">
                        <tr>
                          <td>{studentData.s_admission}</td>
                          <td>{studentData.s_name}</td>
                          <td>{studentData.sf_name}</td>
                          <td>{studentData.sm_name}</td>
                          <td className="text-center">
                            {echo(studentData.s_class)}
                          </td>
                          <td className="text-center">
                            {echo(studentData.s_section)}
                          </td>
                          <td className="text-center">
                            {echo(studentData.s_roll)}
                          </td>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-success waves-effect waves-light"
              >
                More
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger dropdown-toggle px-3 waves-effect waves-light"
                onMouseEnter={() =>
                  (moreOptions.current.style.display = "block")
                }
              >
                {" "}
              </button>
              <center>
                <div
                  className="dropdown-menu dropDwnFix"
                  ref={moreOptions}
                  onMouseLeave={() =>
                    (moreOptions.current.style.display = "none")
                  }
                >
                
                  <Link
                    to={`/app/student-payments?referrer=fee-deposit&s_id=${studentData.s_id}`}
                    className="dropdown-item"
                  >
                    <i className="fa fa-address-book" aria-hidden="true"></i>{" "}
                    Payments history
                  </Link>
                </div>
              </center>
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            borderRadius: 2,
            backgroundColor: "#e5e2e28c",
            padding: 18,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              fontSize: 14,
            }}
          >
            <div className="div">
              <p>
                <strong>Religion:</strong>
                <br />
                {echo(studentData.s_rel)}
              </p>
            </div>
            <div className="div" style={{ marginLeft: "25%" }}>
              <strong> Category:</strong>
              <br />
              {echo(studentData.s_cast)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              fontSize: 14,
            }}
          >
            <div className="div">
              <p>
                <strong>Admission Date:</strong>
                <br />
                {String(studentData.created_at).split(" ")[0]}
              </p>
            </div>
            <div className="div" style={{ marginLeft: "20%" }}>
              <strong>DOB:</strong>
              <br />
              {studentData.s_bd}
            </div>
            <div className="div" style={{ marginLeft: "20%" }}>
              <strong>Aadhar No:</strong>
              <br />
              {echo(studentData.s_uid)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              fontSize: 14,
            }}
          >
            <div className="div">
              <p>
                <strong>Parent Email:</strong>
                <br />
                {echo(studentData.s_email)}
              </p>
            </div>
            <div className="div" style={{ marginLeft: "16.5%" }}>
              <strong>Phone No:</strong>
              <br />
              {echo(studentData.s_contact)}
            </div>

            <div className="div" style={{ marginLeft: "17%" }}>
              <strong>Alternate Contact:</strong>
              <br />
              {echo("")}
            </div>
          </div>
        </div>

        <div
          style={{
            borderRadius: 2,
            backgroundColor: "#e5e2e28c",
            padding: 18,
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 14,
            }}
          >
            <div className="div">
              <strong>Parament Address:</strong>
              <br />
              {echo(studentData.s_address)}
            </div>
            <div className="div">
              <strong>Parament Address:</strong>
              <br />
              {echo("")}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
