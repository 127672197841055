import { combineReducers } from "@reduxjs/toolkit";
import { feesReducer } from "./reducers/fees/feesReducer";
import { loginReducer } from "./reducers/login/loginReducer";
import { schoolReducer } from "./reducers/school/schoolReducer";
import { studentReducer } from "./reducers/students/studentReducer";
import { warehouseReducer } from "./reducers/warehouse/warehouseReducer";

const rootReducer = combineReducers({
  loginPage: loginReducer,
  feesDepositePage: feesReducer,
  studentListPage: studentReducer,
  schoolData: schoolReducer,
  warehouse: warehouseReducer,
});

export default rootReducer;
