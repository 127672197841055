import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFeeReceiptInfoAPI } from "../apis/feesAPIs";
import { useDispatch, useSelector } from "react-redux";
import { set_receipt_info } from "../redux/reducers/fees/actions";
import { amountInWords, localeDateFormatter, toINR } from "../components/utils";

export default function RecieptPaymentView() {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  const student = useSelector((s) => s.feesDepositePage.student);
  const feeReceipts = useSelector((s) => s.feesDepositePage.feeReceipts);
  const feeReceiptInfo = useSelector((s) => s.feesDepositePage.feeReceiptInfo);

  //local state
  const [feeReceipt, setFeeReceipt] = useState(null);

  useEffect(() => {
    const receipt = urlSearch.get("r_id");
    getReceiptInfo(receipt);
    setFeeReceipt(feeReceipts.find((r) => r.fee_receipt === receipt));
  }, []);

  const getReceiptInfo = async (receipt) => {
    const payload = {
      fee_receipt: receipt,
      academic_session: schoolInfo.academic_session.academic_session,
    };
    const fri = await getFeeReceiptInfoAPI(payload);
    console.log(fri);
    if (fri.status) {
      dispatch(set_receipt_info(fri.data));
    }
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div id="">
          <div className="alert " role="alert"></div>
          <div className="row">
            <div className="col-md-10 mb-4">
              <div>
                <div className="card card-cascade narrower">
                  <div
                    className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center"
                    style={{ width: "30%" }}
                  >
                    <a href="" className="white-text mx-3">
                      Student Details
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover table-sm">
                        <thead className="thead">
                          <tr>
                            <th>Admi. No</th>
                            <th>Student Name</th>
                            <th>Father's Name</th>
                            <th>Mother's Name</th>
                            <th>Class</th>
                            <th>Section</th>
                            <th>Roll No</th>
                          </tr>
                        </thead>
                        <tbody className="sdetail">
                          <tr>
                            <td>{student.s_admission}</td>
                            <td className="capi">{student.s_name}</td>
                            <td className="capi">{student.sf_name}</td>
                            <td className="capi">{student.sm_name}</td>
                            <td className="text-center">{student.s_class}</td>
                            <td className="text-center">{student.s_section}</td>
                            <td className="text-center">{student.s_roll}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              {/* <!-- Button trigger modal --> */}
              <a
                href="https://quantumfms.ranchitechnopark.com/index.php/dashboard/print/2018605"
                target="blank"
                className="btn btn-sm btn-success waves-effect waves-light"
              >
                Print
              </a>{" "}
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 mb-4">
              <div>
                <div className="card card-cascade narrower">
                  <div
                    className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center"
                    style={{ width: "30%" }}
                  >
                    <a href="" className="mx-3 white-text">
                      Payment Details
                    </a>
                  </div>
                  {feeReceipt ? (
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table_width table-sm">
                          <thead className="thead">
                            <tr>
                              <th>Receipt number</th>
                              <th className="text-center">Deposite Date</th>
                              <th className="text-right">Academic Session</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{feeReceipt.fee_receipt}</td>
                              <td className="text-center">
                                {localeDateFormatter(
                                  feeReceipt.created_at,
                                  "date"
                                )}
                              </td>
                              <td className="text-right">
                                {feeReceipt.academic_session}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover table-sm">
                          <thead className="thead">
                            <tr>
                              <th>#</th>
                              <th>Descriptions</th>
                              <th className="text-center">Month(s)</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody className="sdetail">
                            {feeReceiptInfo && feeReceiptInfo.length
                              ? feeReceiptInfo.map((fr, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{fr.fee_type}</td>
                                    <td
                                      className="text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {fr.months}
                                    </td>
                                    <td className="text-right">
                                      {toINR(fr.fee_amount)}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="text-left">
                            Amount in words
                            <br />
                            <strong style={{ textTransform: "capitalize" }}>
                              {feeReceipt.amount_in_word + "only"}
                            </strong>
                          </p>
                          <p className="text-right">
                            Total
                            <br />
                            <strong>{toINR(feeReceipt.total_amount)}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
