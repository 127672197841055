import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { schoolInfoAPI } from "../apis/schoolAPIs";
import {
  set_data_loaded,
  set_fee_structures,
  set_school_info,
} from "../redux/reducers/school/actions";
import FeeStructureTable from "../components/common/feeStructureTable";
import { getSchoolFeeStructureAPI } from "../apis/feesAPIs";

export default function FeeSettings() {
  const { openModal } = useOutletContext();
  const location = useLocation();

  const dispatch = useDispatch();
  const schoolInfo = useSelector((state) => state.schoolData.schoolInfo);

  const moreOptions2 = useRef(null);
  const [lastViewedClass, setLastViewedClass] = useState(null);

  useEffect(() => {
    console.log("re ran");
    getSchoolInfo();
    getSchoolFeeStructure();
  }, [location.search]);

  const getSchoolInfo = () => {
    if (schoolInfo.hasOwnProperty("classes")) {
      schoolInfoAPI()
        .then((school) => {
          if (school.status) {
            dispatch(set_school_info(school.data));
            dispatch(set_data_loaded(true));
          } else {
            dispatch(set_data_loaded(true));
          }
        })
        .catch((e) => {});
    }
  };

  const getSchoolFeeStructure = async () => {
    const payload = {
      schoo_id: schoolInfo.basic["id"],
      academic_session: schoolInfo.academic_session["academic_session"],
    };
    getSchoolFeeStructureAPI(payload)
      .then((fs) => {
        if (fs.status) {
          dispatch(set_fee_structures(fs.data));
        }
      })
      .catch((e) => {});
  };

  const handleDeleteFee = (fee_id) => {
    openModal("delete", {
      type: "fee-category",
      id: fee_id,
      redirect: "fee-settings",
    });
  };

  const handleCE = (e) => {
    const id = String(e.target.id).split("_")[1];

    //reset prev viewed class
    if (lastViewedClass && id != lastViewedClass) {
      const lastEl = document.getElementById("con_" + lastViewedClass);
      lastEl.style.maxHeight = null;
    }
    //new class view
    const el = document.getElementById("con_" + id);
    if (el.style.maxHeight) {
      el.style.maxHeight = null;
    } else {
      el.style.maxHeight = el.scrollHeight + "px";
    }
    setLastViewedClass(id);
  };

  const editFeeCategory = (fee_id, fee_category) => {
    openModal("editFeeCategory", {
      id: fee_id,
      redirect: "fee-settings",
      fee_category: fee_category,
    });
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row">
          <div className="col-md-7 mb-4">
            <div class=" card-cascade narrower">
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="mb-2"
              >
                <div className="view view-cascade gradient-card-header p-1 z-depth-2 topm rounded narrower py-0 mx-4  d-flex justify-content-between align-items-center">
                  <div className="white-text mx-3">
                    <strong>Class Fee Structure</strong>
                  </div>
                </div>
                {/* more on MouseEnter */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <a
                    href="#"
                    className="text-success"
                    onClick={() => openModal("newFeeStructure")}
                  >
                    <i className="fa fa-plus"></i> <strong>Create New</strong>
                  </a>
                </div>
              </div>

              <div class="card-body str_scroll cardscroll">
                {schoolInfo.hasOwnProperty("classes") ? (
                  schoolInfo.classes.map((clss, i) => (
                    <React.Fragment>
                      <button
                        id={"c_" + i}
                        onClick={(e) => handleCE(e)}
                        className="collapsible"
                        type="button"
                      >
                        Class-{clss.class_name}
                      </button>

                      <div
                        className="content"
                        id={"con_" + i}
                        style={{ marginTop: 5 }}
                      >
                        <FeeStructureTable
                          cls={clss.class_name}
                          openModal={openModal}
                          key={"fst-" + i}
                        />
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>No data Available</p>
                    <button
                      style={{
                        marginLeft: 5,
                        border: "none",
                        backgroundColor: "#ccc",
                        cursor: "pointer",
                        padding: 2,
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderRadius: 3,
                      }}
                      onClick={() => openModal("newFeeStructure")}
                    >
                      Create New
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-5 mb-4">
            <div className="card-cascade narrower ">
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="mb-2"
              >
                <div className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center">
                  <div className="white-text mx-3">
                    <strong>Default Fee Types</strong>
                  </div>
                </div>
                {/* more on MouseEnter */}
                <div
                  onClick={() => moreOptions2.current.classList.add("show")}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // backgroundColor: "#ccc",
                    width: 50,
                    alignItems: "center",
                  }}
                >
                  <div className="dotdotcls" style={{ marginLeft: 5 }}></div>
                  <div className="dotdotcls"></div>
                  <div className="dotdotcls"></div>
                </div>

                <div
                  className="dropdown-menu more feesettingTogle"
                  x-placement="bottom-start"
                  style={{
                    minWeight: "7rem",
                    padding: 0,
                    marginTop: 30,
                  }}
                  ref={moreOptions2}
                  onMouseLeave={() =>
                    moreOptions2.current.classList.remove("show")
                  }
                >
                  <button
                    className="dropdown-item"
                    style={{ fontSize: 13 }}
                    onClick={() => openModal("newFeeType")}
                  >
                    <i className="fa fa-plus"></i> Add new
                  </button>

                  <button className="dropdown-item" style={{ fontSize: 13 }}>
                    <i className="fa fa-question"></i> Help
                  </button>
                </div>
                {/* close more on MouseEnter */}
              </div>
              <div className="card-body fee_scroll cardscroll">
                <div className="table-responsive">
                  <table id="user_fee" className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th></th>
                        <th>Fee Types</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schoolInfo.hasOwnProperty("school_fees") ? (
                        schoolInfo.school_fees.map((fees, i) => (
                          <tr>
                            <td>
                              <div
                                style={{
                                  background: "grey",
                                  borderRadius: 5,
                                  height: 5,
                                  width: 5,
                                  display: "none",
                                }}
                              ></div>
                            </td>
                            <td>{fees.fee_types}</td>
                            <td className="text-right" style={{ fontSize: 10 }}>
                              <a
                                href="#"
                                className="text-success"
                                onClick={() =>
                                  editFeeCategory(fees.id, fees.fee_types)
                                }
                              >
                                <i className="fa fa-pencil"></i>{" "}
                                <strong>Edit</strong>
                              </a>
                              <a
                                href="#"
                                className="text-danger "
                                style={{ marginLeft: 10 }}
                                onClick={() => handleDeleteFee(fees.id)}
                              >
                                <i className="fa fa-remove"></i>{" "}
                                <strong>Delete</strong>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No data Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
