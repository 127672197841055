import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { APP_DATA } from "../../app-data";
import { saveNewFeeStructureAPI } from "../../apis/feesAPIs";
import { notify } from "../utils";
import { createSearchParams, useNavigate } from "react-router-dom";

export default function AddNewFeeStructure(props) {
  const navigate = useNavigate();
  const schoolInfo = useSelector((state) => state.schoolData.schoolInfo);
  const formData = useRef(structuredClone(APP_DATA.feeStructureForm.allFields));

  const handleForm = (e) => {
    if (e.target.value) {
      formData.current[e.target.id] = e.target.value;
      document.getElementById("err_" + e.target.id).innerText = "";
    }
  };

  const handleSaveFeeStructure = async () => {
    let finalValidation = 1;
    for (const f in formData.current) {
      if (formData.current[f] === undefined || formData.current[f] === "") {
        finalValidation = 0;
        document.getElementById("err_" + f).innerHTML =
          APP_DATA.errorMessage.requiredField;
      } else {
        document.getElementById("err_" + f).innerText = "";
      }
    }
    if (finalValidation) {
      formData.current["academic_session"] =
        schoolInfo.academic_session.academic_session;
      const newFeeStructure = await saveNewFeeStructureAPI(formData.current);
      console.log(newFeeStructure);
      if (newFeeStructure.status) {
        navigate({
          pathname: "/app/fee-settings",
          search: createSearchParams({
            referrer: "create-fee-structure",
            tNode: Date.now(),
          }).toString(),
        });
        notify("success", "Fee stucture successfully created");
        //finally
        props.functions.closeModal();
      }
    } else {
      notify("error", "Data validation failed");
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="" style={{ fontSize: 16 }}>
          Add a new fee structure
        </h5>
        <button
          type="button"
          className="close"
          onClick={() => props.functions.closeModal()}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="form-group" style={{ width: "20%" }}>
              <label className="fml" for="csname">
                Class
              </label>
              <select
                id="class"
                name="class"
                className="form-control form-control-sm"
                onChange={(e) => handleForm(e)}
              >
                <option disabled selected>
                  Select
                </option>
                {schoolInfo.hasOwnProperty("classes")
                  ? schoolInfo.classes.map((clss, i) => (
                      <option value={clss.class_name}>{clss.class_name}</option>
                    ))
                  : null}
              </select>
              <span className="errMessage" id="err_class"></span>
            </div>
            <div className="form-group" style={{ width: "52%" }}>
              <label className="fml" for="csname">
                Select Fee Type
              </label>
              <select
                id="fee_type"
                name="fee_type"
                className="form-control form-control-sm"
                onChange={(e) => handleForm(e)}
              >
                <option value={""} disabled selected>
                  Select
                </option>
                {schoolInfo.hasOwnProperty("school_fees")
                  ? schoolInfo.school_fees.map((fees, i) => (
                      <option value={fees.fee_types}>{fees.fee_types}</option>
                    ))
                  : null}
              </select>
              <span className="errMessage" id="err_fee_type"></span>
            </div>
            <div className="form-group" style={{ width: "25%" }}>
              <label className="fml" for="csname">
                Amount
              </label>
              <input
                id="fee_amount"
                type="text"
                name="fee_amount"
                onChange={(e) => handleForm(e)}
                className="form-control form-control-sm"
              />
              <span class="errMessage" id="err_fee_amount"></span>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => handleSaveFeeStructure()}
          className="btn btn-sm btn-success mb-2 waves-effect waves-light"
        >
          save
        </button>
        <button
          type="button"
          onClick={() => props.functions.closeModal()}
          className="btn btn-sm btn-danger mb-2 waves-effect waves-light"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
