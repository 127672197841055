import {
  SET_SCHOOL_INFO,
  DATA_LOADED,
  SET_DEFAULT_ACADEMIC,
  RESET_SCHOOL_DATA,
  SET_FEE_STRUCTURES,
} from "./actions";

const initialState = {
  schoolInfo: {},
  dataLoaded: false,
  feeStructures: [],
};

export const schoolReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case SET_SCHOOL_INFO:
      return {
        ...prevState,
        schoolInfo: action.payload,
      };
    case DATA_LOADED:
      return {
        ...prevState,
        dataLoaded: action.payload,
      };
    case SET_DEFAULT_ACADEMIC:
      return {
        ...prevState,
        schoolInfo: action.payload,
      };
    case SET_FEE_STRUCTURES:
      return {
        ...prevState,
        feeStructures: action.payload,
      };
    case RESET_SCHOOL_DATA:
      return initialState;
    default:
      return prevState;
  }
};
