// export const API_URL = "http://localhost:8080/";
export const API_URL = 'https://fms-server-1.ranchitechnopark.com/public/'

// POST method
export const postData = async (url = "", data = {}) => {
  return new Promise((resolve, reject) => {
    if (url === "auth/login") {
      fetch(API_URL + url, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    } else {
      const authData = JSON.parse(window.sessionStorage.getItem("auth_data"));
      const authBody = {
        ...data,
        auth_key: authData.access_token,
      };
      if (authData) {
        fetch(API_URL + url, {
          method: "POST",
          body: JSON.stringify(authBody),
        })
          .then((response) => {
            console.log(response);
            resolve(response.json());
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      } else {
        return;
      }
    }
  });
};

// GET method
export const getData = async (url = "", data = {}) => {
  const response = await fetch(API_URL + url, {
    method: "GET",
  });
  return response.json();
};
