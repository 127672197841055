import { APP_LOGIN,APP_LOGOUT } from "./actions"

const initialState = {
    loggedIn:false
}

export const loginReducer = (prevState=initialState,action)=>{
    switch(action.type){
        case APP_LOGIN:
            return {
                ...prevState,
                loggedIn:true
            }
        case APP_LOGOUT:
            return initialState
        default:
            return prevState
    }
}