import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { updateFeeCategoryAPI } from "../../apis/feesAPIs";
import { notify } from "../utils";

export default function EditFeeCategory(props) {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const formData = useRef(structuredClone({ fee_types: "" }));
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);

  const handleForm = (e) => {
    if (e.target.value) {
      formData.current[e.target.id] = e.target.value;
      document.getElementById("err_" + e.target.id).innerText = "";
    }
  };

  const handleSaveFeeType = async () => {
    if (
      !formData.current["fee_types"] ||
      props.data.fee_category === formData.current["fee_types"]
    ) {
      return;
    }
    const payload = {
      fee_id: props.data.id,
      new_fee_category: formData.current["fee_types"],
    };
    const feeUpdated = await updateFeeCategoryAPI(payload);
    if (feeUpdated.status) {
      navigate({
        pathname: "/app/" + props.data.redirect,
        search: createSearchParams({
          referrerP: "update-fee-category",
          tNode: Date.now(),
        }).toString(),
      });
      notify("success", "Fee type successfully updated");
      props.functions.closeModal();
    } else {
      notify("error", "something went wrong! Please try again");
    }
  };
  return (
    <React.Fragment>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="" style={{ fontSize: 16 }}>
            Add a new fee category
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => props.functions.closeModal()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="form-group" style={{ width: "70%" }}>
                <label className="fml" for="csname">
                  Fee category
                </label>
                <input
                  id="fee_types"
                  type="text"
                  name="fee_types"
                  onChange={(e) => handleForm(e)}
                  className="form-control form-control-sm"
                  defaultValue={props.data.fee_category}
                />
                <span class="errMessage" id="err_fee_types"></span>
              </div>
              <div className="form-group" style={{ width: "27%" }}>
                <label className="fml" for="csname">
                  Status
                </label>
                <input
                  readOnly={true}
                  id="fee_types"
                  type="text"
                  name="fee_types"
                  onChange={(e) => handleForm(e)}
                  className="form-control form-control-sm"
                  placeholder="Enabled"
                />
                <span class="errMessage" id="err_fee_types"></span>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => handleSaveFeeType()}
            className="btn btn-sm btn-success mb-2 waves-effect waves-light"
          >
            save
          </button>
          <button
            type="button"
            onClick={() => props.functions.closeModal()}
            className="btn btn-sm btn-danger mb-2 waves-effect waves-light"
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
