export const SET_STUDENT = "SET_STUDENT";
export const SET_FEE_HISTORY = "SET_FEE_HISTORY";
export const DATA_LOADED = "DATA_LOADED";
export const INIT_FEE_DEPOSIT = "INIT_FEE_DEPOSIT";
export const SET_CLASS_FEES = "SET_CLASS_FEES";
export const SET_TOTAL_FEE_AMOUNT = "SET_TOTAL_FEE_AMOUNT";
export const SET_FEE_RECEIPTS = "SET_FEE_RECEIPTS";
export const SET_RECEIPT_INFO = "SET_RECEIPT_INFO";

export const set_student = (studentData) => {
  return {
    type: SET_STUDENT,
    payload: studentData,
  };
};

export const set_fee_history = (feeData) => {
  return {
    type: SET_FEE_HISTORY,
    payload: feeData,
  };
};

export const set_data_loaded = (state) => {
  return {
    type: DATA_LOADED,
    payload: state,
  };
};

export const init_fee_deposit = (feeData) => {
  return {
    type: INIT_FEE_DEPOSIT,
    payload: feeData,
  };
};

export const set_class_fees = (feeData) => {
  return {
    type: SET_CLASS_FEES,
    payload: feeData,
  };
};

export const set_total_fee_amount = (total) => {
  return {
    type: SET_TOTAL_FEE_AMOUNT,
    payload: total,
  };
};

export const set_fee_receipt = (feeReceipts) => {
  return {
    type: SET_FEE_RECEIPTS,
    payload: feeReceipts,
  };
};

export const set_receipt_info = (receiptInfo) => {
  return {
    type: SET_RECEIPT_INFO,
    payload: receiptInfo,
  };
};
