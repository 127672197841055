import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { updateFeeStructure } from "../../apis/feesAPIs";
import { notify } from "../utils";

export default function EditFeeStructure(props) {
  const navigate = useNavigate();
  const schoolInfo = useSelector((state) => state.schoolData.schoolInfo);
  const formData = useRef({});
  const feeStructures = useSelector((s) => s.schoolData.feeStructures);

  // local state
  const [feeStructure, setFeeStructure] = useState({});

  useEffect(() => {
    getFeeStructure();
  }, []);

  const getFeeStructure = async () => {
    // const fs = await getFeeStructureByIdAPI({fee_id:props.data['id']})
    const fs = feeStructures.find((fs) => fs.id == props.data["id"]);
    setFeeStructure(fs);
  };

  const handleForm = (e) => {
    if (e.target.value) {
      formData.current[e.target.id] = e.target.value;
      document.getElementById("err_" + e.target.id).innerText = "";
    }
  };

  const handleSaveFeeStructure = async () => {
    console.log(formData.current)
    if (formData.current["fee_amount"]) {
      const reqPayload = {
        fee_id: props.data["id"],
        fee_amount: formData.current["fee_amount"],
      };

      const updated = await updateFeeStructure(reqPayload);
      if (updated.status && updated.data) {
        navigate({
          pathname: "/app/fee-settings",
          search: createSearchParams({
            referrer: "update-fee-structure",
            tNode: Date.now(),
          }).toString(),
        });
        //finally
        notify("success", "Fee structure has been updated");
        props.functions.closeModal();
      }
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="" style={{ fontSize: 16 }}>
          Add a new fee structure
        </h5>
        <button
          type="button"
          className="close"
          onClick={() => props.functions.closeModal()}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="form-group" style={{ width: "20%" }}>
              <label className="fml" for="csname">
                Class
              </label>
              <select
                id="class"
                name="class"
                onChange={(e) => handleForm(e)}
                defaultValue={feeStructure.class}
                className="form-control form-control-sm"
              >
                <option value={feeStructure.class} selected>
                  {feeStructure.class}
                </option>
              </select>
              <span className="errMessage" id="err_class"></span>
            </div>
            <div className="form-group" style={{ width: "52%" }}>
              <label className="fml" for="csname">
                Select Fee Type
              </label>
              <select
                id="fee_type"
                name="fee_type"
                className="form-control form-control-sm"
                onChange={(e) => handleForm(e)}
                defaultValue={feeStructure.fee_type}
              >
                <option value={feeStructure.fee_type} selected>
                  {feeStructure.fee_type}
                </option>
              </select>
              <span className="errMessage" id="err_fee_type"></span>
            </div>
            <div className="form-group" style={{ width: "25%" }}>
              <label className="fml" for="csname">
                Amount
              </label>
              <input
                id="fee_amount"
                type="text"
                name="fee_amount"
                onChange={(e) => handleForm(e)}
                className="form-control form-control-sm"
                defaultValue={feeStructure.fee_amount}
              />
              <span class="errMessage" id="err_fee_amount"></span>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => handleSaveFeeStructure()}
          className="btn btn-sm btn-success mb-2 waves-effect waves-light"
        >
          save
        </button>
        <button
          type="button"
          onClick={() => props.functions.closeModal()}
          className="btn btn-sm btn-danger mb-2 waves-effect waves-light"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
