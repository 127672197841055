import React from "react";
import { deleteFeeCategoryAPI, deleteFeeStructureByClassAPI } from "../../apis/feesAPIs";
import { createSearchParams, useNavigate } from "react-router-dom";
import { notify } from "../utils";

export default function DeleteModal(props) {
  const navigate = useNavigate();

  const handleDeleteFeeType = async () => {
    let deleted = 0;
    let d = {};
    switch (props.data.type) {
      case "fee-category":
        d = await deleteFeeCategoryAPI({ fee_id: props.data.id });
        if (d.status) {
          deleted = 1;
        }
        break;
      case "fee-structure":
        d = await deleteFeeStructureByClassAPI({ fee_id: props.data.id });
        if (d.status) {
          deleted = 1;
        }
        break;
      default:
        break;
    }
    if (deleted) {
      navigate({
        pathname: "/app/"+props.data.redirect,
        search: createSearchParams({
          referrer: "delete-fee-category",
          tNode: Date.now(),
        }).toString(),
      });
      notify("success", "Fee type successfully deleted");
      //finally
      props.functions.closeModal();
    }
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="" style={{ fontSize: 16 }}>
          Are you sure?
        </h5>
        <button
          type="button"
          className="close"
          onClick={() => props.functions.closeModal()}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form>
          <p style={{ color: "red" }}>
            Please note that Associated data will also be deleted.
          </p>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => props.functions.closeModal()}
          className="btn btn-sm btn-success mb-2 waves-effect waves-light"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={() => handleDeleteFeeType()}
          className="btn btn-sm btn-danger mb-2 waves-effect waves-light"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
