import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./App.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./layouts/landingPage";
import DashboardView from "./views/dashboardView";
import StudentsList from "./views/studentsListView";
import FeeDeposit from "./views/feeDepositView";
import Admission from "./views/admissionView";
import FeeSettings from "./views/feeSettings";
import LoginView from "./views/loginView";
import PageLoader from "./views/pageLoader";
import FeeDepositeStudentSearchView from "./views/feeDepositeStudentSearchView";
import SchoolSettingsView from "./views/SchoolSettings";
import { PersistGate } from "redux-persist/integration/react";
import MorePaymentHistory from "./views/morePaymentHistory";
import StudentDetailsView from "./views/studentDetailsView";
import CollectFeeView from "./views/collectFeeView";
import RecieptPaymentView from "./views/recieptPaymentView";
import PrintView from "./views/printView";
import Due_reports from "./views/due_reports";
import Test from "./views/test";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            {/* login routes */}
            <Route path="/test" element={<PageLoader />} />
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
            <Route exact path="/auth/login" element={<LoginView />} />
            <Route exact path="/auth/admin" element={<LoginView />} />

            {/* after login routes */}
            <Route exact path="/app" element={<LandingPage />}>
              <Route
                path="/app"
                element={<Navigate to="/app/dashboard" replace />}
              />
              <Route path="/app/admission" element={<Admission />} />
              <Route path="/app/fee-deposit" element={<FeeDeposit />} />
              <Route path="/app/dashboard" element={<DashboardView />} />
              <Route path="/app/fee-settings" element={<FeeSettings />} />
              <Route path="/app/students-list" element={<StudentsList />} />
              <Route
                path="/app/school-settings"
                element={<SchoolSettingsView />}
              />
              <Route
                path="/app/student-search"
                element={<FeeDepositeStudentSearchView />}
              />
              <Route
                path="/app/student-details"
                element={<StudentDetailsView />}
              />
              <Route
                path="/app/student-payments"
                element={<MorePaymentHistory />}
              />

              <Route path="/app/collectFeeView" element={<CollectFeeView />} />

              <Route path="/app/view-reciept" element={<RecieptPaymentView />} />

              <Route path="/app/print-reciept" element={<PrintView />} />

              <Route path="/app/due-reports" element={<Due_reports/>} />

              <Route
                path="/app/test-only"
                element={<Test />}
              />

            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
