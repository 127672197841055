import {postData} from './index'

export const loginAPI = (userAuthData)=>{
    return new Promise((resolve,reject)=>{
        postData('auth/login', userAuthData).then((data) => {
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            reject({
                error:'net::Error',
                message:'Network error detected, Please check your Internet connection.'
            })
        });
    })
}
