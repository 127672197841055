import React,{ useRef } from "react";

export default function Due_reports() {

    const moreOptions2 = useRef(null);

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert " role="alert"></div>
        <div className="row">
          <div className="col-md-10 mb-4">
            <div>
              <div className="card card-cascade narrower">
                <div className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center" style={{width:'25%'}}>
                  <a href="#" className="mx-3 white-text">
                    Dues Reports
                  </a>
                </div>
                <div className="card-body">
                  <form
                    action="https://quantumfms.ranchitechnopark.com/index.php/reports/generate_due_reports"
                    method="post"
                    accept-charset="utf-8"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <label style={{ color: "red", fontSize: 14 }}>
                          Select Class
                        </label>
                        <select
                          id="due_class"
                          name="due_class"
                          className="custom-select form-control form-control-sm my-1 mr-sm-2"
                          required=""
                        >
                          <option disabled="" selected="">
                            Select Class
                          </option>
                          <option value="NUR">NUR</option>
                          <option value="LKG">LKG</option>
                          <option value="UKG">UKG</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label style={{ color: "red", fontSize: 14 }}>
                          Select Due Type
                        </label>
                        <select
                          id="due_fee_type"
                          name="due_fee_type"
                          className="custom-select form-control form-control-sm my-1 mr-sm-2"
                          required=""
                        >
                          <option disabled="" selected="">
                            Due Type
                          </option>
                          <option>Addmission Fee</option>
                          <option>Annual Fee</option>
                          <option>Caution Money</option>
                          <option>Computer Fee</option>
                          <option>Development Fund</option>
                          <option>Examination Fee</option>
                          <option>fine</option>
                          <option>I. Card</option>
                          <option>Lab Fee</option>
                          <option>Late Fine</option>
                          <option>Misc.</option>
                          <option>Monthly Fee</option>
                          <option>P. Pupil Fund</option>
                          <option>School Leaving Certificate</option>
                          <option>Transportation Fee</option>
                          <option>Tuition Fee</option>
                          <option>Uniform</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label style={{ color: "red", fontSize: 14 }}>
                          Select Due Month
                        </label>
                        <select
                          id="due_month"
                          name="due_month"
                          className="custom-select form-control form-control-sm my-1 mr-sm-2"
                          required=""
                        >
                          <option disabled="" selected="">
                            Due Month
                          </option>
                          <option value="january">January</option>
                          <option value="february">February</option>
                          <option value="march">March</option>
                          <option value="april">April</option>
                          <option value="may">May</option>
                          <option value="june">June</option>
                          <option value="july">July</option>
                          <option value="august">August</option>
                          <option value="september">September</option>
                          <option value="october">October</option>
                          <option value="november">November</option>
                          <option value="december">December</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <br />
                        <button
                          className="btn btn-sm btn-danger waves-effect waves-light"
                          id="get_due"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-success waves-effect waves-light"
              >
                More
              </button>
              <button
                disabled=""
                type="button"
                className="btn btn-sm btn-danger dropdown-toggle px-3 waves-effect waves-light"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {" "}
              </button>
              <div className="dropdown-menu more">
                <a className="dropdown-item" href="">
                  <i className="fa fa-print mt-0"></i> Print Details
                </a>
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#chnageMonth"
                >
                  <i className="fa fa-refresh mt-0"></i> Change Week
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-4">
            <div className=" card-cascade narrower">
              
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="">
                  <strong><p>Due Report : [ Class: | Due Type: | Month: ]</p></strong>
                </div>
                {/* more on MouseEnter */}
                <div
                  onMouseEnter={() =>
                    moreOptions2.current.classList.add("show")
                  }
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // backgroundColor: "#ccc",
                    width: 50,
                    alignItems: "center",
                  }}
                >
                  <div className="dotdotcls" style={{ marginLeft: 5 }}></div>
                  <div className="dotdotcls"></div>
                  <div className="dotdotcls"></div>
                </div>

                <div
                  className="dropdown-menu more due_reports_Togle"
                  x-placement="bottom-start"
                  style={{
                    minWeight: "7rem",
                    padding: 0,
                    marginTop: 30,
                  }}
                  ref={moreOptions2}
                  onMouseLeave={() =>
                    moreOptions2.current.classList.remove("show")
                  }
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    style={{ fontSize: 13 }}
                  >
                    <i className="fa fa-file-excel-o mt-0"></i> Export To Excel
                  </a>

                  <a
                    className="dropdown-item"
                    href="#"
                    style={{ fontSize: 13 }}
                  >
                    <i className="fa fa-envelope mt-0"></i> Bulk Email
                  </a>
                </div>
                {/* close more on MouseEnter */}
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th className="text-center">Sr. No</th>
                        <th className="text-left">Student Name</th>
                        <th className="text-center">Roll No</th>
                        <th className="text-center">Class</th>
                        <th className="text-center">Section</th>
                        <th className="text-center">Contact</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="8" className="text-center">
                          Please Select Options to View Defaulters/Generate
                          Reports
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation"></nav>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
