import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { studentSearchAPI } from "../apis/studentAPIs";
import { APP_DATA } from "../app-data";
import { notify } from "../components/utils";
import { set_student } from "../redux/reducers/fees/actions";
import { set_recent_student } from "../redux/reducers/students/actions";

export default function FeeDeposit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moreOptions = useRef(null);
  const moreOptions1 = useRef(null);

  const formData = useRef(
    Object.assign({}, APP_DATA.searchInputs.studentSearch)
  );
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  const recentStudents = useSelector(
    (s) => s.warehouse.recentSearchedStudents
  );

  const formChangeHandler = (e) => {
    formData.current[e.target.id] = e.target.value;
  };

  const handleStudentSearch = (e) => {
    const form = {
      id: formData.current.id,
      by: "admission_number",
      academic_session: schoolInfo.academic_session.academic_session,
    };
    studentSearchAPI(form)
      .then((student) => {
        if (student.status && student.data.length) {
          dispatch(set_student(student.data[0]));
          navigate({
            pathname: "/app/student-search",
            search: createSearchParams({
              referrer: "fee-deposite-studentSearch",
              tNode: Date.now(),
            }).toString(),
          });
        } else {
          notify("error", "No student found");
        }
      })
      .catch((e) => {
        notify("error", "Something went wrong");
      });
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row pb-3">
          <div className="col-md-7">
            <div className="card wow fadeIn">
              <div
                className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center"
                style={{ width: "30%" }}
              >
                <a href="" className="white-text mx-3">
                  Student Search
                </a>
              </div>
              <div className="card-body pt-3 pb-4">
                <div className="row">
                  <div className="col-md-8">
                    <input
                      type="text"
                      id="id"
                      className="form-control"
                      placeholder="Enter Admission No."
                      required
                      onChange={(e) => formChangeHandler(e)}
                    />
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={(e) => handleStudentSearch(e)}
                      className="btn btn-success cfc3 btn-block "
                      style={{ borderRadius: 25, fontSize: 14, padding: 8 }}
                    >
                      <i class="fa fa-spinner fa-spin"></i> Search
                    </button>
                                                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-7 mb-4">
            <div className=" card-cascade narrower">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Recent Students</p>

                {/* more on MouseEnter */}
                <div
                  onMouseEnter={() => moreOptions.current.classList.add("show")}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="dotdotcls"></div>
                  <div className="dotdotcls"></div>
                  <div className="dotdotcls"></div>
                </div>

                <div
                  className="dropdown-menu more  style4toggle"
                  x-placement="bottom-start"
                  style={{ minWeight: "7rem", padding: "0 15px" }}
                  ref={moreOptions}
                  onMouseLeave={() =>
                    moreOptions.current.classList.remove("show")
                  }
                >
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-print mt-0"></i>More
                  </a>
                </div>
                {/* close more on MouseEnter */}
              </div>
              <div className=" recent_print_con">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-sm"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead className="thead">
                      <tr>
                        <th>Roll No</th>
                        <th>Admi. No.</th>
                        <th className="text-left">Class</th>
                        <th>Student Name</th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody className="tbody td">
                      {recentStudents.length ? (
                        recentStudents.map((s,i) => (
                          <tr key={'td_'+i}>
                            <td>{s.s_roll}</td>
                            <td>{s.s_admission}</td>
                            <td>{s.s_class}</td>
                            <td>{s.s_name}</td>
                            <td className="text-right">
                              <Link
                                to={`/app/student-payments?referrer=fee-deposit&s_id=${s.s_id}`}
                                className="badge badge-pill badge-success texSize"
                              >
                                Payments
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>No data available</tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 mb-4">
            <div className=" card-cascade narrower">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Recent Payments</p>
                {/* more on MouseEnter */}
                <div
                  onMouseEnter={() =>
                    moreOptions1.current.classList.add("show")
                  }
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="dotdotcls"></div>
                  <div className="dotdotcls"></div>
                  <div className="dotdotcls"></div>
                </div>

                <div
                  className="dropdown-menu more  style4toggle1"
                  x-placement="bottom-start"
                  style={{ minWeight: "7rem", padding: "0 15px" }}
                  ref={moreOptions1}
                  onMouseLeave={() =>
                    moreOptions1.current.classList.remove("show")
                  }
                >
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-print mt-0"></i>More
                  </a>
                </div>
                {/* close more on MouseEnter */}
              </div>
              <div className=" recent_print_con">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th className="text-left">Class</th>
                        <th className="text-left">Student Name</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="tbody"></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
