import React from 'react'

export default function PrintView() {
  return (
    <React.Fragment>
        <main>
            <div className="container pt-5" id="stdPrint">
                <div className="row pt-4">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <center>
                                    <img className="img-fluid" src="https://quantumfms.ranchitechnopark.com/assets/school_logo.png"/>
                                    <h6 className="text-center">Ormanjhi Dahu, Ranchi, Jharkhand<br/>Contact: +91 7462 097 608, Email:  info@quantumiti.in</h6>
                                    <hr/>
                                </center>
                            </div>
                        </div>
                        {/* <!-- Table markup--> */}
                        <h6 style={{fontSize: 13}}>Student Details</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <table id="customers">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <th>Father's Name</th>
                                        <th className="text-center">Class / Section</th>
                                        <th className="text-center">Roll No</th>
                                        <th className="text-center">Admission No</th>
                                    </tr>
                                    <tr>
                                        <td>kumari puju</td>
                                        <td>newas</td>
                                        <td className="text-center">10 , A</td>
                                        <td className="text-center">01</td>
                                        <td className="text-center">rtp23-24/01</td>
                                    </tr>
                                    </tbody></table>
                                    <hr/>
                                </div>
                            </div>

                            <h6 style={{fontSize: 13}}>Payment Details</h6>
                            <div className="row">
                                <div className="col-md-4">
                                    <table id="customers">
                                        <tbody>
                                            <tr>
                                                <th>Deposite Date</th>
                                                <th className="text-center">Fee Recipt No</th>
                                                <th className="text-right">Academic Session</th>
                                            </tr>
                                            <tr>
                                                <td>28-01-2023</td>
                                                <td className="text-center">2018605</td>
                                                <td className="text-right">2023-2024</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <table id="customers">
                                    <tbody>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Descriptions</th>
                                            <th className="text-center">Month(s)</th>
                                            <th className="text-right">Amount</th>
                                        </tr>
                                        <tr>
                                            </tr><tr>
                                            <td>1</td>  
                                            <td>Examination Fee</td>
                                            <td className="text-center">january</td>
                                            <td className="text-right">3000</td>
                                        </tr>		                    		                    
                                    </tbody>
                                </table>
                                <hr/>
                                <p className="text-right" style={{fontSize: 13}}>Total Fees : 3000</p>
                                <p className="text-left" style={{fontSize: 13}}>Amount in words:<br/><strong style={{textTransform: 'capitalize'}}>three thousand rupees </strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <center>
		        <button type="button" onclick="printDiv('stdPrint')" class="btn btn-primary btn-sm">Print</button>
	        </center>
        </main>
    </React.Fragment>
  )
}
