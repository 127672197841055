import { postData } from "./index";

export const feeSearchAPI = (searchData) => {
  return new Promise((resolve, reject) => {
    postData("fees/search", searchData)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getClassFeesAPI = (s_class, academic_session) => {
  return new Promise((resolve, reject) => {
    postData("fees/get-by-class", {
      s_class: s_class,
      academic_session: academic_session,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getStudentUnpaidMonthsAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/get-student-unpaid-months", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const completeFeePaymentAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/complete-fee-payment", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getAllFeeReceiptsAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/get-fee-receipts", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getFeeReceiptInfoAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/get-receipt-info", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const saveNewFeeCategoryAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/save-new-fee-category", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const deleteFeeCategoryAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/delete-fee-category", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const saveNewFeeStructureAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/add-fee-structure", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getFeeStructureByClassAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/get-fee-structure", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const getFeeStructureByIdAPI = (reqPayload)=>{
  return new Promise((resolve, reject) => {
    postData("fees/get-fee-structure-by-id", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}

export const getSchoolFeeStructureAPI = (reqPayload)=>{
  return new Promise((resolve, reject) => {
    postData("fees/get-all-fee-structure", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}

export const deleteFeeStructureByClassAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    postData("fees/delete-fee-structure", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const updateFeeCategoryAPI = (reqPayload)=>{
  return new Promise((resolve, reject) => {
    postData("fees/update-fee-category", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}


export const updateFeeStructure= (reqPayload)=>{
  return new Promise((resolve, reject) => {
    postData("fees/update-fee-stucture", reqPayload)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
}
