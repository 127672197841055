import React, { forwardRef } from 'react'
import { useRef } from 'react'
import { useImperativeHandle } from 'react'

export const LoaderCircle = forwardRef((props,ref)=>{
    const loader = useRef()

    useImperativeHandle(ref,()=>({
        active(){
            loader.current.style.display = ''
        },
        inactive(){
            loader.current.style.display = 'none'
        }
    }))
    return (
        <div ref={loader} style={{margin: 0,position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',zIndex:'30',display:'none'}}>
            <div
                className="loaderTheme-sm"
                style={{
                    borderTop:'2px solid #0071bc'
                }}
            >
            </div>
        </div>
    )
})
