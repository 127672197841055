export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
export const SET_FEE_HISTORY = "SET_FEE_HISTORY";
export const DATA_LOADED = "DATA_LOADED";
export const SET_STUDENT_DATA = "SET_STUDENT_DATA";

export const set_student_list = (studentData) => {
  return {
    type: SET_STUDENT_LIST,
    payload: studentData,
  };
};

export const set_fee_history = (feeData) => {
  return {
    type: SET_FEE_HISTORY,
    payload: feeData,
  };
};

export const set_data_loaded = (state) => {
  return {
    type: DATA_LOADED,
    payload: state,
  };
};

export const set_student_data = (studentData) => {
  return {
    type: SET_STUDENT_DATA,
    payload: studentData,
  };
};
