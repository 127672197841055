export const APP_DATA = {
    loginForm:{
        allFields:{
            username:'',
            password:'',
        },
        optionalFields:[]
    },
    admissionForm:{
        allFields:{
            s_name:'',
            sf_name:'',
            sm_name:'',
            s_roll:'',
            s_admission:'',
            s_class:'',
            s_section:'',
            s_gender:'',
            s_bd:'',
            s_cast:'',
            s_rel:'',
            s_address:'',
            s_contact:'',
            s_email:'',
            s_uid:'',
            academic_session:''

        },
        optionalFields:[
            's_email',
            's_uid',
            's_roll',
            's_section'
        ]
    },
    schoolInfoForm:{
        allFields:{
            name:'',
            email:'',
            contact_no:'',
            address:'',
            mobile_no:'',
            state:'',
            district:'',
            pin_code:'',
            udice_code:''
        },
        optionalFields:[
            'udice_code'
        ]
    },
    feeStructureForm:{
        allFields:{
            class:'',
            fee_type:'',
            fee_amount:'',
        },
        optionalFields:[]
    },

    searchInputs:{
        studentSearch:{
            id:''
        }
    },

    errorMessage:{
        requiredField:'this is a required field'
    },
}