import React, { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { completeFeePaymentAPI } from "../apis/feesAPIs";
import { LoaderCircle } from "../components/common/loaderCircle";
import LoaderStandAlone from "../components/common/loaderStandAlone";
import { amountInWords, notify, toINR } from "../components/utils";
import {
  init_fee_deposit,
  set_total_fee_amount,
} from "../redux/reducers/fees/actions";

export default function FeeDepositeStudentSearchView() {
  const navigate = useNavigate();
  const { openModal } = useOutletContext();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const moreOptions = useRef(null);
  const dispatch = useDispatch();

  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  const student = useSelector((state) => state.feesDepositePage.student);
  const initDepositFees = useSelector(
    (s) => s.feesDepositePage.initDepositFees
  );
  const totalAmount = useSelector((s) => s.feesDepositePage.totalFeeAmount);

  useEffect(() => {
    // console.log(initDepositFees);
  }, []);

  const discardAddedFeeParticular = (e) => {
    const id = String(e.target.id).split("_")[1];
    const tempInitFee = [...initDepositFees];
    const removeAbleFP = tempInitFee.splice(parseInt(id), 1);

    if (!tempInitFee.length) {
      dispatch(set_total_fee_amount(0));
    } else {
      dispatch(
        set_total_fee_amount(
          parseFloat(totalAmount) - parseFloat(removeAbleFP[0].fee_amount)
        )
      );
    }
    dispatch(init_fee_deposit([...tempInitFee]));
  };

  const completeFeePayment = async () => {
    const reqObj = {
      s_id: student.s_id,
      fee_data: initDepositFees,
      academic_session: schoolInfo.academic_session.academic_session,
    };
    const p = await completeFeePaymentAPI(reqObj);
    if (p.status) {
      navigate({
        pathname: "/app/student-search",
        search: createSearchParams({
          referrer: "payment-success",
          tNode: Date.now(),
        }).toString(),
        replace: true,
      });
      notify("success", "Payment successfully completed");
    } else {
      notify("error", "Unable to process this request");
    }
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row">
          <div className="col-md-10">
            <div className="card card-cascade narrower">
              <div
                className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower py-2 mx-4  d-flex justify-content-between align-items-center"
                style={{ width: "20%" }}
              >
                <a href="" className="white-text mx-3">
                  Student Details
                </a>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th>Admi. No</th>
                        <th>Student Name</th>
                        <th>Father's Name</th>
                        <th>Mother's Name</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Roll No</th>
                      </tr>
                    </thead>
                    <tbody className="sdetail">
                      <tr>
                        <td>{student.s_admission}</td>
                        <td className="capi">{student.s_name}</td>
                        <td className="capi">{student.sf_name}</td>
                        <td className="capi">{student.sm_name}</td>
                        <td className="text-center">{student.s_class}</td>
                        <td className="text-center">{student.s_section}</td>
                        <td className="text-center">{student.s_roll}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-success waves-effect waves-light"
              >
                More
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger dropdown-toggle px-3 waves-effect waves-light"
                onMouseEnter={() =>
                  (moreOptions.current.style.display = "block")
                }
              >
                {" "}
              </button>
              <center>
                <div
                  className="dropdown-menu dropDwnFix"
                  ref={moreOptions}
                  onMouseLeave={() =>
                    (moreOptions.current.style.display = "none")
                  }
                >
                  <a href="#" className="dropdown-item more">
                    <i className="fa fa-address-book" aria-hidden="true"></i>{" "}
                    Details
                  </a>
                  <Link
                    to={`/app/student-payments?referrer=fee-deposit&s_id=${student.s_id}`}
                    className="dropdown-item"
                  >
                    <i className="fa fa-address-book" aria-hidden="true"></i>{" "}
                    Payments history
                  </Link>
                </div>
              </center>
            </div>
          </div>
        </div>
        <br />

        {/* fee particular and payment gateway */}
        <div className="row">
          <div className="col-md-10 mb-4">
            <div
              className=""
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div> Fee Description</div>
              <div>
                <button
                  // style={{ borderRadius: 20 }}
                  onClick={() => openModal("addNewFee")}
                  className="btn btn-sm btn-success waves-effect waves-light cfc3"
                >
                  Add New
                </button>
              </div>
            </div>
            <div className="   card-cascade narrower p-0">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th>#</th>
                        <th>Fee Particulars</th>
                        <th>Month</th>
                        <th className="text-right">Amount</th>
                        {/* <th className="text-right">Action</th> */}
                      </tr>
                    </thead>
                    <tbody className="sdetail" id="temp_fee_data">
                      {initDepositFees && initDepositFees.length ? (
                        initDepositFees.map((fees, i) => {
                          return (
                            <React.Fragment key={"fp_" + i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{fees.fee_type}</td>
                                <td>{fees.month}</td>
                                <td className="text-right">
                                  {toINR(fees.fee_amount)}
                                </td>
                                <td
                                  className="text-right"
                                  style={{ fontSize: 10 }}
                                >
                                  <button
                                    type="button"
                                    id={"btn-del_" + i}
                                    className="text-danger"
                                    style={{
                                      marginLeft: 10,
                                      marginRight: 20,
                                      border: "none",
                                      borderRadius: 13,
                                      cursor: "pointer",
                                      height: 25,
                                      width: 25,
                                    }}
                                    onClick={(e) =>
                                      discardAddedFeeParticular(e)
                                    }
                                  >
                                    X
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td colspan="6" className="text-center">
                            Add Fees to continue
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <table className="table table-hover table-sm">
                    <tbody className="sdetail">
                      {initDepositFees && initDepositFees.length ? (
                        <React.Fragment>
                          <tr style={{ alignContent: "center" }}>
                            <td
                              style={{
                                fontWeight: "bold",
                                verticalAlign: "middle",
                              }}
                              className="text-right"
                            >
                              Total Amount
                            </td>
                            <td
                              className="text-right"
                              style={{
                                fontWeight: "bold",
                                verticalAlign: "middle",
                              }}
                            >
                              {toINR(totalAmount)}
                              <br />
                              {/* <p>{amountInWords(totalAmount)}</p> */}
                            </td>
                            <td className="text-right">
                              <button
                                className="btn btn-sm btn-success waves-effect waves-light"
                                onClick={() => completeFeePayment()}
                              >
                                Complete payment
                              </button>
                              <button className="btn btn-sm btn-success waves-effect waves-light cfc3">
                                Discard
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end fee particular and paymemt gateway */}
      </main>
    </React.Fragment>
  );
}
