import React from "react";
import { Link } from "react-router-dom";

export default function SideBarMenu() {
  return (
    <React.Fragment>
      <div className="sidebar-fixed position-fixed">
        <div className="waves-effect">
          <img src={"../../assets/img/rtpark_green.png"} className="img-fluid" alt=""style={{width:180,margin:10}} />
        </div>
        <div className="list-group list-group-flush menu">
          <p>
            <i className="fa fa-bars" aria-hidden="true"></i> Menu
          </p>
          <Link to="/app/dashboard" className="list-group-item waves-effect">
            <i className="fa fa-pie-chart mr-3"></i>Dashboard
          </Link>
          <Link to="/app/fee-deposit" className="list-group-item  waves-effect">
            <i className="fa fa-inr mr-3"></i>Fee Deposit
          </Link>
          <Link
            to="/app/students-list"
            className="list-group-item waves-effect"
          >
            <i className="fa fa-users mr-3"></i>Student List
          </Link>
          <Link to="/app/admission" className="list-group-item waves-effect">
            <i className="fa fa-plus mr-3"></i>Add Students
          </Link>
        </div>
        <div className="list-group list-group-flush menu">
          <p className="pt-3">
            <i className="fa fa-cog" aria-hidden="true"></i>Settings
          </p>
        
          <Link
            to="/app/fee-settings"
            className="list-group-item  waves-effect"
          >
            <i className="fa fa-gear mr-3"></i>Fees
          </Link>
          <Link
            to="/app/school-settings"
            className="list-group-item  waves-effect"
          >
            <i className="fa fa-compass mr-3"></i>School
          </Link>
          <Link
            to="/app/test-only"
            className="list-group-item  waves-effect"
          >
            <i className="fa fa-compass mr-3"></i>Test
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
