import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { createStudent } from "../apis/studentAPIs";
import { APP_DATA } from "../app-data";
import { createSearchParams, useNavigate } from "react-router-dom";
import { notify } from "../components/utils";

export default function Admission() {
  const navigate = useNavigate();
  const formData = useRef(Object.assign({}, APP_DATA.admissionForm.allFields));
  const admissionForm = useRef(null)

  const schoolInfo = useSelector((state) => state.schoolData.schoolInfo);
  const dataLoaded = useSelector((state) => state.schoolData.dataLoaded);

  const formChangeHandler = async (event) => {
    // const validationResponse = await inputFieldValidator(event)
    const validationResponse = {
      valid: true,
      error_message: "input is not valid",
    };
    if (validationResponse.valid) {
      formData.current[event.target.name] = event.target.value;
      document.getElementById("err_" + event.target.name).innerText = "";
    } else {
      document.getElementById("err_" + event.target.name).innerText =
        validationResponse["error_message"];
    }
  };

  
  const handleCreateStudent = async (e) => {
    let finalValidation = 0;
    let formattedData = {};
    formData.current["academic_session"] =
      schoolInfo.academic_session.academic_session;
    //@f is the current field
    for (const f in formData.current) {
      let error_message = APP_DATA.errorMessage.requiredField;
      if (
        APP_DATA.admissionForm.optionalFields.includes(f) &&
        !formData.current[f]
      ) {
        finalValidation = finalValidation + 1;
        continue;
      }
      // @ (!f in formData.current)-> condition should never occure, so maintain accordingly
      if (formData.current[f] === undefined || formData.current[f] === "") {
        finalValidation = finalValidation - 1;
        document.getElementById("err_" + f).innerText = error_message;
      } else {
        finalValidation = finalValidation + 1;
        document.getElementById("err_" + f).innerText = "";
      }
      formattedData[f] = formData.current[f];
    }
    formData.current["academic_session"] =
      schoolInfo.academic_session.academic_session;
      console.log(formattedData)

    if (finalValidation === Object.keys(formData.current).length) {
      const newStudent = await createStudent(formattedData);
      navigate({
        pathname: "/app/admission",
        search: createSearchParams({
          referrer: "create",
          tNode: Date.now(),
        }).toString(),
      });
      if (e.target.dataset.action === "save") {
        formData.current = Object.assign({}, APP_DATA.admissionForm.allFields);
      } else {
        formData.current = Object.assign({}, APP_DATA.admissionForm.allFields);
      }
      notify("success", "Admission successful");
      admissionForm.current.reset()
    } else {
      notify("error", "Input Validation failed");
      return;
    }
  };

  useEffect(() => {}, []);
  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row">
          <div className="col-md-12">
            <form ref={admissionForm}>
              <div className="card-cascade narrower">
                <div className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center" style={{width:'20%'}}>
                  <div className="white-text mx-3">
                    <strong>Add Student</strong>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 mb-4" style={{ height: 450 }}>
                      <h6>Student Details</h6>
                      <div className="form-group">
                        <input
                          type="text"
                          name="s_name"
                          className="names form-control form-control-sm"
                          placeholder="Student Name"
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_s_name"></span>
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          name="sf_name"
                          className=" names form-control form-control-sm"
                          placeholder="Father's Name"
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_sf_name"></span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="sm_name"
                          className=" names form-control form-control-sm"
                          placeholder="Mother's Name"
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_sm_name"></span>
                      </div>

                      <h6>Gender</h6>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="s_gender"
                          value="Male"
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <label
                          className="form-check-label"
                          for="exampleRadios1"
                        >
                          Male
                        </label>
                      {/* </div>
                      <div className="form-check"> */}
                        <input
                          className="form-check-input"
                          type="radio"
                          name="s_gender"
                          value="Female"
                          onChange={(e) => formChangeHandler(e)}
                          style={{marginLeft:10}}
                        />
                        <label
                          className="form-check-label"
                          for="exampleRadios2"
                          style={{marginLeft:30}}
                        >
                          Female
                        </label>
                        <span className="errMessage" style={{marginTop: 5}} id="err_s_gender"></span>
                      </div>
                      <br />
                      <h6>Date of Birth</h6>
                      <div className="form-group">
                        <div className="input-group date" id="datetimepicker1">
                          <input
                            type="date"
                            name="s_bd"
                            className="form-control form-control-sm"
                            onChange={(e) => formChangeHandler(e)}
                          />
                        </div>
                        <span className="errMessage" id="err_s_bd"></span>
                      </div>
                      <div className="form-group">
                        <select
                          name="s_cast"
                          className="custom-select form-control form-control-sm my-1 mr-sm-2"
                          onChange={(e) => formChangeHandler(e)}
                        >
                          <option disabled selected>
                            Category
                          </option>
                          <option value="OBC">OBC</option>
                          <option value="SC">SC</option>
                          <option value="ST">ST</option>
                          <option value="GEN">GEN</option>
                        </select>
                        <span className="errMessage" id="err_s_cast"></span>
                      </div>

                      <div className="form-group">
                        <select
                          name="s_rel"
                          className="custom-select form-control form-control-sm my-1 mr-sm-2"
                          onChange={(e) => formChangeHandler(e)}
                        >
                          <option disabled selected>
                            Religion
                          </option>
                          <option value="Islam">Islam</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Christian">Christion</option>
                          <option value="Jain">Jain</option>
                          <option value="Others">Other</option>
                        </select>
                        <span className="errMessage" id="err_s_rel"></span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h6>Academic Details</h6>
                      <div className="form-group">
                        <input
                          type="text"
                          name="academic_session"
                          className="form-control form-control-sm"
                          value={schoolInfo.academic_session.academic_session}
                          disabled
                        />
                        <span
                          className="errMessage"
                          id="err_academic_session"
                        ></span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="s_roll"
                          className="form-control form-control-sm"
                          placeholder="Roll No."
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_s_roll"></span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="s_admission"
                          className="form-control form-control-sm"
                          placeholder="Admission No."
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span
                          className="errMessage"
                          id="err_s_admission"
                        ></span>
                      </div>
                      <div className="">
                        <h6>Class</h6>
                        <select
                          name="s_class"
                          className="custom-select form-control form-control-sm my-1 mr-sm-2"
                          onChange={(e) => formChangeHandler(e)}
                        >
                          <option selected disabled>
                            Select
                          </option>
                          {schoolInfo.classes.map((clss, i) => (
                            <React.Fragment key={"class_" + i}>
                              <option value={clss.class_name}>
                                {clss.class_name}
                              </option>
                            </React.Fragment>
                          ))}
                        </select>
                        <span className="errMessage" id="err_s_class"></span>
                      </div>

                      <h6 className="mt-4">Section</h6>
                      <div style={{display:"inline-flex"}}>
                      {schoolInfo.sections.map((sec, i) => (
                        <div className="form-check"style={{paddingRight:10}}>
                          <React.Fragment key={"sec" + i}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="s_section"
                              value={sec.section}
                              onChange={(e) => formChangeHandler(e)}
                              />
                            <label
                              className="form-check-label"
                              for={sec.section}
                            >
                              {sec.section}
                            </label>
                          </React.Fragment>
                        </div>
                      ))}
                      </div>
                      <span className="errMessage" id="err_s_section"></span>
                          
                    </div>

                    <div className="col-md-4">
                      <h6>Address & Contact Details</h6>
                      <div className="form-group">
                        <input
                          type="text"
                          name="s_uid"
                          className="form-control form-control-sm"
                          placeholder="Adhar No"
                          maxlength="12"
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_s_uid"></span>
                      </div>
                      <h6>Contact Details</h6>
                      <div className="form-group">
                        <input
                          type="text"
                          name="s_contact"
                          className="form-control form-control-sm"
                          maxlength="10"
                          placeholder="Mobile No."
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_s_contact"></span>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="s_email"
                          className="form-control form-control-sm"
                          placeholder="Email Id"
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <span className="errMessage" id="err_s_email"></span>
                      </div>
                      <h6>Address</h6>
                      <div className="form-group">
                        <textarea
                          name="s_address"
                          className="form-control form-control-sm"
                          placeholder="Correspondence or Permanent Address"
                          onChange={(e) => formChangeHandler(e)}
                          rows={4}
                        ></textarea>
                        <span className="errMessage" id="err_s_address"></span>
                      </div>
                      <br />
                      <button
                        type="button"
                        className="btn btn-sm btn-success mb-2"
                        data-action={"save"}
                        onClick={(e) => handleCreateStudent(e)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger mb-2"
                        data-action={"save&new"}
                        onClick={(e) => handleCreateStudent(e)}
                      >
                        save & new
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
