import React from 'react'
import { Link } from 'react-router-dom'

export default function LoginNavView() {
  return (
    <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-theme">
            <div className="navbar-brand"><img src="https://quantumfms.ranchitechnopark.com/assets/img/headlogo.png" hight="60px" width="250"/></div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
        </nav>
    </React.Fragment>
  )
}
