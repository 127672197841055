import toast, { Toaster } from "react-hot-toast";

export const localeDateFormatter = (dateString, returnType = "") => {
  let formattedDate = "";
  let locale = "fr-CA";
  try {
    if (!dateString) {
      return null;
    }
    const d = new Date(dateString);
    if (returnType === "date") {
      formattedDate = d.toLocaleDateString(locale);
    } else if (returnType === "time") {
      formattedDate = d.toLocaleTimeString();
    } else if (returnType === "date-time") {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      formattedDate = d.toLocaleString("en-US", options);
    } else {
      formattedDate = d.toLocaleString();
    }
    return formattedDate;
  } catch (e) {
    console.log(e);
  }
};

export const successNotificationStyle = {
  style: {
    border: "1px solid #713200",
    padding: "16px",
    color: "#713200",
  },
  iconTheme: {
    primary: "#713200",
    secondary: "#FFFAEE",
  },
};

export const errorNotificationStyle = {
  style: {
    border: "1px solid #f00",
    padding: "16px",
    color: "#713200",
  },
  iconTheme: {
    primary: "#f00",
    secondary: "#FFFAEE",
  },
};

export const notify = (type, message) => {
  switch (type) {
    case "success":
      return toast.success(message, { ...successNotificationStyle });
    case "error":
      return toast.error(message, { ...errorNotificationStyle });
  }
};

export const echo = (text) => {
  if (text) {
    return text;
  } else {
    return "--";
  }
};

export const toINR = (amount = 0) => {
  return parseFloat(amount).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
};

export const amountInWords = (num = 0) => {
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num)
    .substring(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
      : "";
  return str;
};
