import {
  SET_STUDENT,
  SET_FEE_HISTORY,
  DATA_LOADED,
  INIT_FEE_DEPOSIT,
  SET_CLASS_FEES,
  SET_TOTAL_FEE_AMOUNT,
  SET_FEE_RECEIPTS,
  SET_RECEIPT_INFO,
} from "./actions";

const initialState = {
  student: {},
  dataLoaded: false,
  studentFeeHistory: [],
  initDepositFees: [],
  classFees: [],
  totalFeeAmount: 0,
  feeReceipts: [],
  feeReceiptInfo: {},
};

export const feesReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT:
      return {
        ...prevState,
        student: action.payload,
      };
    case SET_FEE_HISTORY:
      return {
        ...prevState,
        studentFeeHistory: action.payload,
      };
    case DATA_LOADED:
      return {
        ...prevState,
        dataLoaded: action.payload,
      };
    case INIT_FEE_DEPOSIT:
      return {
        ...prevState,
        initDepositFees: action.payload,
      };
    case SET_CLASS_FEES:
      return {
        ...prevState,
        classFees: action.payload,
      };
    case SET_TOTAL_FEE_AMOUNT:
      return {
        ...prevState,
        totalFeeAmount: action.payload,
      };
    case SET_FEE_RECEIPTS:
      return {
        ...prevState,
        feeReceipts: action.payload,
      };
    case SET_RECEIPT_INFO:
      return {
        ...prevState,
        feeReceiptInfo: action.payload,
      };
    default:
      return prevState;
  }
};
