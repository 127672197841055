import { SET_RECENT_STUDENT } from "./actions";
const initialState = {
  recentSearchedStudents: [],
};

export const warehouseReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case SET_RECENT_STUDENT:
      return {
        ...prevState,
        recentSearchedStudents: action.payload,
      };
    default:
      return prevState;
  }
};
