import {postData} from './index'

export const schoolInfoAPI = ()=>{
    return new Promise((resolve,reject)=>{
        postData('/schools/get-school-info').then((data) => {
            console.log(data)
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            reject([])
        });
    })
}

export const setDefaultAcademicAPI = (academicData)=>{
    return new Promise((resolve,reject)=>{
        postData('/schools/set-default-academic',academicData).then((data) => {
            console.log(data)
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            reject([])
        });
    })   
}

export const createSchoolAPI = (school)=>{
    return new Promise((resolve,reject)=>{
        postData('schools/create',school).then((data) => {
            console.log(data)
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            reject([])
        });
    })  
}

export const updateSchoolAPI = (school)=>{
    return new Promise((resolve,reject)=>{
        postData('schools/update',school).then((data) => {
            console.log(data)
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            reject([])
        });
    })  
}
