import {postData} from './index'

export const studentSearchAPI = (searchData)=>{
    return new Promise((resolve,reject)=>{
        postData('students/search', searchData).then((data) => {
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            resolve([])
        });
    })
}

export const getStudentByidAPI = (id)=>{
    return new Promise((resolve,reject)=>{
        postData('students/get_by_id', {s_id:id}).then((data) => {
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            resolve([])
        });
    })
}

export const getFilteredStudents = (filter)=>{
    return new Promise((resolve,reject)=>{
        postData('students/filter', filter).then((data) => {
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            resolve([])
        });
    })
}

export const createStudent = (studentData)=>{
    return new Promise((resolve,reject)=>{
        postData('students/create', studentData).then((data) => {
            resolve(data)
        }).catch((e)=>{
            console.log(e)
            resolve([])
        });
    })
}