import React, { useEffect } from "react";
import { useRef } from "react";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { loginAPI } from "../apis/authAPIs";
import { APP_DATA } from "../app-data";
import { LoaderCircle } from "../components/common/loaderCircle";
import LoginNavView from "../components/login/loginNavView";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { app_login, app_logout } from "../redux/reducers/login/actions";
import { notify } from "../components/utils";

export default function LoginView() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginLoader = useRef(null);
  const formData = useRef(Object.assign({}, APP_DATA.loginForm.allFields));

  useEffect(() => {
    const authData = JSON.parse(window.sessionStorage.getItem("auth_data"));
    if (authData) {
      const decodedToken = jwt_decode(authData.access_token);
      if (parseInt(decodedToken.exp + "000") <= Date.now()) {
        dispatch(app_logout());

        return navigate({
          replace: true,
          pathname: "/auth/login",
          search: createSearchParams({
            referrer: "auth-login-sessionExpired",
            tNode: Date.now(),
          }).toString(),
        });
      } else {
        dispatch(app_login());

        return navigate({
          replace: true,
          pathname: "/app",
          search: createSearchParams({
            referrer: "auth-login-sessionActive",
            tNode: Date.now(),
          }).toString(),
        });
      }
    } else {
      dispatch(app_logout());

      return navigate({
        replace: true,
        pathname: "/auth/login",
        search: createSearchParams({
          referrer: "auth-login-sessionUnauthorized",
          tNode: Date.now(),
        }).toString(),
      });
    }
  }, []);

  const handleForm = (event) => {
    formData.current[event.target.name] = event.target.value;
  };

  const handleLogin = async (e) => {
    let finalValidation = 0;
    const form = formData.current;

    //@f is the current field
    for (const f in formData.current) {
      let error_message = APP_DATA.errorMessage.requiredField;
      if (APP_DATA.loginForm.optionalFields.includes(f)) {
        finalValidation = finalValidation + 1;
        continue;
      }
      //@ (!f in formData.current)-> condition should never occure, so maintain accordingly
      if (formData.current[f] === undefined || formData.current[f] === "") {
        finalValidation = finalValidation - 1;
        // document.getElementById('err_'+f).innerText = error_message
      } else {
        finalValidation = finalValidation + 1;
        // document.getElementById('err_'+f).innerText = ''
      }
    }

    if (finalValidation === Object.keys(formData.current).length) {
      if (location.pathname.split("/")[2] === "admin") {
        form["login_type"] = "admin";
      } else {
        form["login_type"] = "simple";
      }

      loginLoader.current.active();
      loginAPI(form)
        .then((loginData) => {
          console.log(loginData);
          if (loginData.code != 401) {
            window.sessionStorage.setItem(
              "auth_data",
              JSON.stringify({
                auth_type: loginData.auth_type,
                access_token: loginData.access_token,
              })
            );
            navigate({
              pathname: "/app",
              search: createSearchParams({
                referrer: "auth-success",
                tNode: Date.now(),
              }).toString(),
              replace: true,
            });
          } else {
            document.getElementById("auth_error").innerText =
              "Access Denied, Please try again!";
          }
          loginLoader.current.inactive();
        })
        .catch((e) => {
          if (e.error === "net::Error") notify("error", e.message);
          loginLoader.current.inactive();
          return;
        });
    } else {
      return;
    }
  };
  return (
    <React.Fragment>
      <LoginNavView />

      <div className="container">
        <div
          className="row loginf"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-4">
            <form className="text-center mt-5">
              <h5 style={{ fontWeight: "bold" }}>
                {location.pathname.split("/")[2] === "admin"
                  ? "Administrator Login"
                  : "Accountant Login"}
              </h5>

              <p style={{ color: "red" }} id="auth_error"></p>

              <br />
              <input
                type="email"
                name="username"
                placeholder="E-mail"
                onChange={(e) => handleForm(e)}
                className="form-control mb-4 loginput"
              />

              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={(e) => handleForm(e)}
                className="form-control mb-4 loginput"
              />

              <div className="d-flex justify-content-around">
                <div>
                  {location.pathname.split("/")[2] === "admin" ? (
                    <Link to={"/auth/login"}>Accountant Login</Link>
                  ) : (
                    <Link to={"/auth/admin"}>Administrator Login</Link>
                  )}
                </div>

                <div>
                  <Link to={"/auth/forgot-password"}>Forgot password?</Link>
                </div>
              </div>

              {/* <!-- Sign in button --> */}

              <button
                type="button"
                className="btn btn-info btn-block my-4 waves-effect waves-light"
                onClick={(e) => handleLogin(e)}
                style={{ height: 45 }}
              >
                <span style={{ display: "" }}>Sign in</span>
                {/* <LoaderCircle/> */}
                <LoaderCircle ref={loginLoader} />
              </button>
            </form>

            <center>
              © 2023-24 |{" "}
              <Link to={""} style={{ color: "blue" }}>
                A Product of RTPark
              </Link>{" "}
              | <Link to={""}>Privacy Policy</Link>
            </center>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
