import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { getStudentByidAPI } from "../apis/studentAPIs";
import { localeDateFormatter, notify } from "../components/utils";
import { set_student_data } from "../redux/reducers/students/actions";
import { feeSearchAPI, getAllFeeReceiptsAPI } from "../apis/feesAPIs";
import {
  set_data_loaded,
  set_fee_history,
  set_fee_receipt,
  set_student,
} from "../redux/reducers/fees/actions";
import LoaderStandAlone from "../components/common/loaderStandAlone";

export default function MorePaymentHistory() {
  const location = useLocation();
  const navigate = useNavigate();

  const urlSearch = new URLSearchParams(location.search);
  const studentList = useSelector(
    (state) => state.studentListPage.studentsList
  );

  const dispatch = useDispatch();
  const studentData = useSelector((state) => state.studentListPage.studentData);
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  const dataLoaded = useSelector((state) => state.feesDepositePage.dataLoaded);
  const studentFeeHistory = useSelector(
    (state) => state.feesDepositePage.studentFeeHistory
  );
  const feeReceipts = useSelector((s) => s.feesDepositePage.feeReceipts);

  useEffect(() => {
    const s_id = urlSearch.get("s_id");
    const feeFilter = {
      s_id: s_id,
      fee_type: "Tuition Fee",
      academic_session: schoolInfo.academic_session.academic_session,
    };

    getStudent(s_id);
    getAllFeeReceipt(s_id);
    handleFilterData(feeFilter);
  }, []);

  const getStudent = async (s_id) => {
    if (urlSearch.get("referrer") === "student-list") {
      dispatch(set_student_data(studentList.find((s) => s.s_id === s_id)));
    } else {
      const student = await getStudentByidAPI(s_id);
      if (student.status && student.data) {
        dispatch(set_student_data(student.data));
      } else {
        notify("error", "Something went wrong!");
      }
    }
  };

  const handleFilterData = (filter) => {
    feeSearchAPI(filter)
      .then((data) => {
        if (data.status) {
          const feeHistory = [];
          for (const f in data.data) {
            feeHistory.push(data.data[f]);
          }
          dispatch(set_fee_history(feeHistory));
          setTimeout(() => {
            dispatch(set_data_loaded(true));
          }, 200);
          setTimeout(() => {
            document.getElementById("fee_type").innerText = filter.fee_type;
          }, 220);
        }
      })
      .catch((e) => {
        notify("error", "Something went wrong!");
      });
  };

  const getAllFeeReceipt = async (s_id) => {
    const payload = {
      s_id: s_id,
      academic_session: schoolInfo.academic_session.academic_session,
    };
    const fr = await getAllFeeReceiptsAPI(payload);
    if (fr.status && fr.data.length) {
      dispatch(set_fee_receipt(fr.data));
    } else {
      dispatch(set_fee_receipt([]));
    }
  };

  const filterByFeeType = (e) => {
    dispatch(set_data_loaded(false));
    if (e.target.value != "none") {
      const feeFilter = {
        s_id: studentData.s_id,
        fee_type: e.target.value,
        academic_session: schoolInfo.academic_session.academic_session,
      };
      handleFilterData(feeFilter);
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="row mt-5">
          <div className="col-md-10">
            <div className="card card-cascade narrower">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="view view-cascade gradient-card-header p-1 z-depth-2 topm rounded narrower py-0 mx-4  d-flex justify-content-between align-items-center">
                  <div className="white-text mx-3">Student information</div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th>Admi. No</th>
                        <th>Student Name</th>
                        <th>Father's Name</th>
                        <th>Mother's Name</th>
                        <th>className</th>
                        <th>Section</th>
                        <th>Roll No</th>
                      </tr>
                    </thead>
                    {studentData ? (
                      <tbody className="sdetail">
                        <tr>
                          <td>{studentData.s_admission}</td>
                          <td className="capi">{studentData.s_name}</td>
                          <td className="capi">{studentData.sf_name}</td>
                          <td className="capi">{studentData.sm_name}</td>
                          <td className="text-center">{studentData.s_class}</td>
                          <td className="text-center">
                            {studentData.s_section}
                          </td>
                          <td className="text-center">{studentData.s_roll}</td>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-success waves-effect waves-light"
              >
                More
              </button>
              <button
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-toggle="dropdown"
                className="btn btn-sm btn-danger dropdown-toggle px-3 waves-effect waves-light"
              >
                {" "}
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item more"
                  href="https://quantumfms.ranchitechnopark.com/index.php/dashboard/student_details/rtp23-24/01"
                >
                  <i className="fa fa-inr" aria-hidden="true"></i> Fee Deposit
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row mb-3">
          <div className="col-md-12">
            <div className="card-cascade narrower">
              <div className="tab-content card-body">
                <div
                  className="tab-pane active"
                  id="allPayments"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="" style={{ width: "30%" }}>
                          <form>
                            <div className="form-group">
                              <label style={{ fontSize: 15 }}>
                                Desposite History
                              </label>
                              <select
                                name="feetype"
                                className="form-control form-control-sm"
                                onChange={(e) => filterByFeeType(e)}
                              >
                                <option selected value={"none"}>
                                  Select
                                </option>
                                {schoolInfo.hasOwnProperty("school_fees")
                                  ? schoolInfo.school_fees.map((fees, i) => (
                                      <>
                                        <option value={fees.fee_types}>
                                          <td>{fees.fee_types}</td>
                                        </option>
                                      </>
                                    ))
                                  : null}
                              </select>
                            </div>
                          </form>
                        </div>
                        <h6 className="">
                          Academic Session:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {schoolInfo.academic_session.academic_session}
                          </span>
                        </h6>
                      </div>
                      <div style={{ height: 150 }}>
                        {dataLoaded ? (
                          <div className="table-responsive">
                            <table className="table table-bordered table-sm">
                              <thead className="thead blue lighten-4">
                                <tr>
                                  <th>Discriptions</th>
                                  {studentFeeHistory.map((history) => (
                                    <th
                                      className="text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {String(history.month).substring(0, 3)}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="tbody">
                                <tr>
                                  <td id="fee_type"></td>
                                  {studentFeeHistory.map((history) => (
                                    <td className="text-center">
                                      {history.fee_amount}
                                    </td>
                                  ))}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <LoaderStandAlone />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-10">
                      <h6 className="">Reciepts | Print Reciepts</h6>
                      <div className="table-responsive">
                        <table className="table table-hover table-sm">
                          <thead className="thead blue lighten-4">
                            <tr>
                              <th className="text-left">Payment Date</th>
                              <th className="text-center">Recipt No</th>
                              <th className="text-center">Amount</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody className="tbody">
                            {feeReceipts && feeReceipts.length ? (
                              feeReceipts.map((receipt, i) => (
                                <React.Fragment>
                                  <tr key={"rec-" + i}>
                                    <td className="text-left">
                                      {localeDateFormatter(
                                        receipt.created_at,
                                        "date"
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {receipt.fee_receipt}
                                    </td>
                                    <td className="text-center">
                                      {receipt.total_amount}
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={`/app/view-reciept?r_id=${receipt.fee_receipt}&s_id=${studentData.s_id}`}
                                        className="badge badge-pill badge-primary"
                                      >
                                        View
                                      </Link>{" "}
                                      <Link
                                        to="/app/print-reciept?r_id=1"
                                        className="badge badge-pill badge-success"
                                      >
                                        Print
                                      </Link>{" "}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No receipts available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
