import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  getClassFeesAPI,
  getStudentUnpaidMonthsAPI,
} from "../../apis/feesAPIs";
import { APP_DATA } from "../../app-data";
import {
  init_fee_deposit,
  set_class_fees,
  set_total_fee_amount,
} from "../../redux/reducers/fees/actions";
import { notify } from "../utils";

export default function AddNewFeeModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  const student = useSelector((state) => state.feesDepositePage.student);
  const initDepositFees =
    useSelector((s) => s.feesDepositePage.initDepositFees) || [];
  const classFees = useSelector((s) => s.feesDepositePage.classFees);

  //local state
  const [unpaidMonths, setUnpaidMonths] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const formData = useRef(
    structuredClone({ fee_type: "", month: "", fee_amount: "" })
  );

  useEffect(() => {
    getClassFees();
    document.getElementById("fee_type").size = 10;
  }, []);

  const getClassFees = async () => {
    const classFees = await getClassFeesAPI(
      student.s_class,
      schoolInfo.academic_session.academic_session
    );

    if (classFees.status && classFees.data.length) {
      dispatch(set_class_fees(classFees.data));
    } else {
      //reset if no fee found for the class
      dispatch(set_class_fees([]));
    }
  };

  const handleSelectFee = async (e) => {
    const rp = {
      s_id: student.s_id,
      fee_type: String(e.target.value).split("_")[0],
      academic_session: schoolInfo.academic_session.academic_session,
    };
    const upm = await getStudentUnpaidMonthsAPI(rp);
    if (upm.status) {
      const sma = [];
      formData.current["fee_type"] = String(e.target.value).split("_")[0];
      formData.current["fee_amount"] = String(e.target.value).split("_")[1];
      if (initDepositFees && initDepositFees.length) {
        const sm = initDepositFees.filter((f) => {
          if (f.fee_type === formData.current["fee_type"]) {
            sma.push(f.month);
          }
        });
      }
      setSelectedMonths(sma);
      setUnpaidMonths(upm.data);
      document.getElementById("show_data").size = 10;
    }
  };

  const handleSelectMonth = (e) => {
    if (!e.target.value) {
      formData.current["month"] = undefined;
      notify("error", "Please select a valid month");
      return;
    }
    formData.current["month"] = e.target.value;
  };

  const handleTempSaveFee = () => {
    let finalValidation = 1;
    for (const f in formData.current) {
      if (formData.current[f] == "" || formData.current[f] === undefined) {
        document.getElementById("err_" + f).innerText =
          APP_DATA.errorMessage.requiredField;
        finalValidation = 0;
      }
    }
    if (finalValidation) {
      let Total = 0;
      if (initDepositFees) {
        initDepositFees.map((f) => {
          Total += parseInt(f.fee_amount);
        });
      }
      const newFp = [];
      Total += parseInt(formData.current["fee_amount"]);
      newFp.push(formData.current);
      dispatch(set_total_fee_amount(Total));
      dispatch(init_fee_deposit([...initDepositFees, ...newFp]));
      //finally
      props.functions.closeModal();
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="" style={{ fontSize: 16 }}>
          Add fee perticular
        </h5>
        <button
          type="button"
          className="close"
          onClick={() => props.functions.closeModal()}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="form-group" style={{ width: "60%" }}>
              <label className="fml" for="csname">
                Fee Amount &amp; Fee Type
              </label>
              <select
                id="fee_type"
                name="fee_amount_type"
                className="form-control form-control-sm"
                onChange={(e) => handleSelectFee(e)}
              >
                <option disabled selected>
                  select fee type and amount
                </option>
                {classFees && classFees.length ? (
                  classFees.map((f, i) => (
                    <option
                      key={"opt_" + i}
                      value={f.fee_type + "_" + f.fee_amount}
                    >
                      {f.fee_type + "__" + f.fee_amount}
                    </option>
                  ))
                ) : (
                  <option value="none">No fees found</option>
                )}
              </select>
              <span className="errMessage" id="err_fee_type"></span>
            </div>
            <div className="form-group" style={{ width: "30%" }}>
              <label className="fml" for="csname">
                Month
              </label>
              <select
                id="show_data"
                name="month"
                disabled={!unpaidMonths.length}
                className="form-control form-control-sm"
                onChange={(e) => handleSelectMonth(e)}
              >
                <option value={""}>Select month</option>
                {unpaidMonths.length ? (
                  unpaidMonths.map((m, i) => (
                    <option
                      value={m}
                      key={"opt_um" + i}
                      disabled={selectedMonths.includes(m)}
                    >
                      {m}
                    </option>
                  ))
                ) : (
                  <option disabled>Loading...</option>
                )}
              </select>
              <span className="errMessage" id="err_month"></span>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => handleTempSaveFee()}
          className="btn btn-sm btn-success mb-2 waves-effect waves-light"
        >
          <i className="fa fa-plus mr-3"></i>Add
        </button>
      </div>
    </div>
  );
}
