export const APP_LOGIN = 'APP_LOGIN'
export const APP_LOGOUT = 'APP_LOGOUT'

export const app_login = ()=>{
    return {
        type:APP_LOGIN
    }
}

export const app_logout = ()=>{
    return {
        type:APP_LOGOUT
    }
}