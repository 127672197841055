import React,{useState} from 'react'

export default function Test() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <React.Fragment>
        <main role="main" className="pt-5 mx-lg-5">
            <a className=" btn btn-warning mt-5" onClick={handleShow}>
              Launch Modal test
            </a>

            <div show={show} onHide={handleClose} className="container modal-container show-hide bg-light"style={{display:'flex', justifyContent:'space-between',flexDirection:'column'}}>
                <div className="modal-header bg-success"style={{display:'flex', justifyContent:'space-between'}}>
                  <div className="heading"><p>Modal Title</p></div>
                  <button className="close" type="button" closeButton>X</button>
                </div>

                <div className="modal-content" style={{maxWidth:"100%",minHeight:'60%'}}>Do you need more time to make a purchase decision?
No modal Launching <br/> Your modal will be waiting for you for customization.</div>

                <div className="modal-footer bg-white">
                  <button className="btn btn-sm btn-danger btn-danger waves-effect"onClick={handleClose}>Close</button>
                  <button className="btn btn-sm btn-outline-success waves-effect">Ok</button>
                </div>
            </div>
        </main>
    </React.Fragment>
  )
}
