import React, { useRef } from "react";
import { useEffect } from "react";
import { createSearchParams, Outlet, useNavigate } from "react-router-dom";
import HeaderBarMenu from "./headerBarMenu";
import SideBarMenu from "./sideBarMenu";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { app_login, app_logout } from "../redux/reducers/login/actions";
import { reset_school_data } from "../redux/reducers/school/actions";
import { Modals } from "./modals";

export default function LandingPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.loginPage.loggedIn);
  const modalsView = useRef(null);

  useEffect(() => {
    const authData = JSON.parse(window.sessionStorage.getItem("auth_data"));
    if (authData) {
      const decodedToken = jwt_decode(authData.access_token);
      console.log(decodedToken);
      if (parseInt(decodedToken.exp + "000") <= Date.now()) {
        dispatch(app_logout());
        return navigate({
          replace: true,
          pathname: "/auth/login",
          search: createSearchParams({
            referrer: "app-landing-sessionExpired",
            tNode: Date.now(),
          }).toString(),
        });
      } else {
        dispatch(app_login());
      }
    } else {
      dispatch(app_logout());
      return navigate({
        replace: true,
        pathname: "/auth/login",
        search: createSearchParams({
          referrer: "app-landing-unauthorized",
          tNode: Date.now(),
        }).toString(),
      });
    }
  }, []);

  const menuHandlers = {
    logout: () => {
      navigate({
        pathname: "/",
        search: createSearchParams({
          referrer: "app-logout",
          tNode: Date.now(),
        }).toString(),
        replace: true,
      });
      dispatch(app_logout());
      dispatch(reset_school_data());
      window.sessionStorage.clear();
    },
    openModal: (name, withData = {}) => {
      modalsView.current.renderModal(name, withData);
    },
  };

  return loggedIn ? (
    <React.Fragment>
      {/* Header bar */}
      <HeaderBarMenu functions={menuHandlers} />
      {/* Sidebar  */}
      <SideBarMenu />
      {/* Route Outlet */}
      <Outlet context={menuHandlers} />
      {/* modals */}
      <Modals ref={modalsView} />
    </React.Fragment>
  ) : null;
}
