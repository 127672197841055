import React, { useRef } from "react";
import { forwardRef, useState, useImperativeHandle } from "react";
import AddNewFeeModal from "../components/modals/addNewFeeModal";
import AddNewFeeType from "../components/modals/addNewFeeType";
import DeleteModal from "../components/modals/deleteModal";
import AddNewFeeStructure from "../components/modals/addNewFeeStructure";
import EditFeeCategory from "../components/modals/editFeeCategory";
import EditFeeStructure from "../components/modals/editFeeStructure";

export const Modals = forwardRef((props, ref) => {
  const modal = useRef();

  const [activeComp, setActiveComp] = useState(null);
  const [modalStyle, setModalStyle] = useState(false);
  const [modalData, setModalData] = useState({});

  useImperativeHandle(ref, () => ({
    renderModal(view, data) {
      setActiveComp(view);
      setModalStyle(true);
      setModalData(data);

      modal.current.classList.add("show");
      modal.current.style.display = "block";
      document.getElementById("overlay").style.display = "block";
    },
  }));

  const componentHandlers = {
    closeModal: () => {
      setModalStyle(false);
      setActiveComp("");

      modal.current.style.display = "none";
      modal.current.classList.remove("show");
      document.getElementById("overlay").style.display = "none";
    },
    handleModel: (d) => {
      if (d === "on") {
        modal.current.classList.add("show");
        modal.current.style.display = "block";
        document.getElementById("overlay").style.display = "block";
      } else {
        modal.current.style.display = "none";
        modal.current.classList.remove("show");
        document.getElementById("overlay").style.display = "none";
      }
    },
  };

  const renderModals = (view, propsData) => {
    switch (view) {
      case "addNewFee":
        return (
          <AddNewFeeModal functions={componentHandlers} data={propsData} />
        );
      case "newFeeType":
        return <AddNewFeeType functions={componentHandlers} data={propsData} />;
      case "delete":
        return <DeleteModal functions={componentHandlers} data={propsData} />;
      case "newFeeStructure":
        return (
          <AddNewFeeStructure functions={componentHandlers} data={propsData} />
        );
      case "editFeeCategory":
        return (
          <EditFeeCategory functions={componentHandlers} data={propsData} />
        );
      case "editFeeStructure":
        return (
          <EditFeeStructure functions={componentHandlers} data={propsData} />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div class="overlay" id="overlay"></div>
      <div className="modal fade show" ref={modal} style={{ zIndex: 500 }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          {renderModals(activeComp, modalData)}
        </div>
      </div>
    </React.Fragment>
  );
});
