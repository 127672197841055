import React from 'react'

export default function LoaderStandAlone() {
  return (
    <div style={{margin: 0,position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',zIndex:'30',display:'block'}}>
        <div
            className="loaderTheme-sm"
            style={{
                borderTop:'2px solid #0071bc'
            }}
        >
        </div>
    </div>
  )
}
