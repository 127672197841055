import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { schoolInfoAPI } from '../apis/schoolAPIs'
import { set_data_loaded, set_school_info } from '../redux/reducers/school/actions'

export default function DashboardView() {

  const dispatch = useDispatch()
  const schoolInfo = useSelector((state)=>state.schoolData.schoolInfo)

  useEffect(()=>{
    getSchoolInfo()
  },[])

  const getSchoolInfo = ()=>{
    if(!schoolInfo.hasOwnProperty('classes')){
      schoolInfoAPI().then((school)=>{
        console.log(school)
        if(school.status){
          dispatch(set_school_info(school.data))
          dispatch(set_data_loaded(true))
        }else{
          dispatch(set_data_loaded(true))
        }
      }).catch((e)=>{
        console.log(e)
      })
    }
  }

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5 mx-3">

        {/* trail card  */}
       
        <div className="row-p">
          <div className="col-xl-3 col-md-6">
            <div className="card card1">
              <div className="card-block">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h5 className="text-c-yellow f-w-600">$30200</h5>
                    <h6 className="text-muted m-b-0" style={{fontSize:12}}>Todays Deposit</h6>
                  </div>
                  <div className="col-4 text-right">
                    {/* <i className="feather icon-bar-chart f-28"></i> */}
                    <i class="fa fa-bar-chart"></i>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-c-yellow cfc1">
                <div className="row align-items-center">
                  <div className="col-9">
                    <p className="text-white m-b-0">% change</p>
                  </div>
                  <div className="col-3 text-right">                
                    <span class="material-symbols-outlined">show_chart</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card card1">
              <div className="card-block">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="text-c-green f-w-600">290+</h4>
                    <h6 className="text-muted m-b-0"style={{fontSize:12}}>Today Deposite</h6>
                  </div>
                  <div className="col-4 text-right">
                    {/* <i className="feather icon-file-text f-28"></i> */}
                    <i class="fa fa-file-text"></i>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-c-green cfc2">
                <div className="row align-items-center">
                  <div className="col-9"> 
                    <p className="text-white m-b-0">% change</p>
                  </div>
                  <div className="col-3 text-right">
                    <span class="material-symbols-outlined">show_chart</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card card1">
              <div className="card-block">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="text-c-pink f-w-600">290+</h4>
                    <h6 className="text-muted m-b-0"style={{fontSize:12}}>Today Deposite</h6>
                  </div>
                  <div className="col-4 text-right">
                    {/* <i className="feather icon-file-text f-28"></i> */}
                    <i class="fa fa-calendar-check-o"></i>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-c-green cfc3">
                <div className="row align-items-center">
                  <div className="col-9"> 
                    <p className="text-white m-b-0">% change</p>
                  </div>
                  <div className="col-3 text-right">
                    <span class="material-symbols-outlined">show_chart</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="card card1">
              <div className="card-block">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="text-c-blue f-w-600">290+</h4>
                    <h6 className="text-muted m-b-0"style={{fontSize:12}}>Today Deposite</h6>
                  </div>
                  <div className="col-4 text-right">
                    {/* <i className="feather icon-file-text f-28"></i> */}
                    <i class="fa fa-download"></i>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-c-green cfc4">
                <div className="row align-items-center">
                  <div className="col-9"> 
                    <p className="text-white m-b-0">% change</p>
                  </div>
                  <div className="col-3 text-right">
                    <span class="material-symbols-outlined">show_chart</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          </div>


        <div className="alert " role="alert"></div>
          <div className="row" style={{marginTop: -10}}>
            <div className="col-md-6 mb-4">
              <div className="card card-cascade narrower">
                  <div className="view view-cascade cfc4 z-depth-1 topm rounded narrower py-2 mx-4  d-flex justify-content-between align-items-center">
                      <a href="#" className="mx-3 cardtab" style={{color:'white'}}>Monthly Sum Review [ 2023-2024 ]</a>
                  </div>              
                  <div className="card-body">
                    <h3>card body</h3>
                  </div>
              </div>                  
            </div>
          
            <div className="col-md-6 mb-4">
              <div className="card card-cascade narrower">
                <div className="view view-cascade cfc1 z-depth-1 topm rounded narrower py-2 mx-4  d-flex justify-content-between align-items-center">
                    <a href="#" className="mx-3 cardtab" style={{color:'white'}}>Monthly Defaulters [ Tuition Fee ]</a>
                </div>
                <div className="card-body">
                  <h3>card body</h3>  
                </div>
              </div>                  
            </div>
          </div> 
              
      </main>
    </React.Fragment>
  )
}
