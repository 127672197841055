import React, { useState } from "react";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useLocation, useNavigate } from "react-router-dom";
import { getFilteredStudents, studentSearchAPI } from "../apis/studentAPIs";
import { APP_DATA } from "../app-data";
import { notify } from "../components/utils";
import {
  set_data_loaded,
  set_student_list,
} from "../redux/reducers/students/actions";

export default function StudentsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const studentList = useSelector(
    (state) => state.studentListPage.studentsList
  );
  const dataLoaded = useSelector((state) => state.studentListPage.dataLoaded);
  const schoolInfo = useSelector((state) => state.schoolData.schoolInfo);

  const formData = useRef(
    Object.assign({}, APP_DATA.searchInputs.studentSearch)
  );
  const [searchby, setSearchBy] = useState("admission_number");

  const formChangeHandler = (e) => {
    formData.current[e.target.id] = e.target.value;
  };

  const searchSelector = (e) => {
    setSearchBy(e.target.value);
  };

  const handleStudentSearch = (e) => {
    const form = {
      by: searchby,
      id: formData.current.id,
      academic_session: schoolInfo.academic_session.academic_session,
    };
    if (!form.id) {
      notify("error", "Input Validation failed");
      return;
    }
    studentSearchAPI(form)
      .then((student) => {
        if (student.status && student.data.length) {
          dispatch(set_student_list(student.data));
          navigate({
            pathname: "/app/students-list",
            search: createSearchParams({
              referrer: "student-List-Search",
              tNode: Date.now(),
            }).toString(),
          });
        } else {
          notify("error", "No student found");
        }
      })
      .catch((e) => {
        console.log(e);
        notify("error", "Something went wrong");
      });
  };

  useEffect(() => {
    dispatch(set_data_loaded(true));
  }, [location.search]);

  const handleClassFilter = (e) => {
    const filter = {
      by: e.target.name,
      key: e.target.value,
      academic_session: schoolInfo.academic_session.academic_session,
    };
    getFilteredStudents(filter)
      .then((student) => {
        if (student.status && student.data.length) {
          dispatch(set_student_list(student.data));
          navigate({
            pathname: "/app/students-list",
            search: createSearchParams({
              referrer: "student-List-Search",
              tNode: Date.now(),
            }).toString(),
          });
        } else {
          notify("error", "No student found");
        }
      })
      .catch((e) => {
        console.log(e);
        notify("error", "Something went wrong");
      });
  };

  return (
    <React.Fragment>
      <main role="main" className="pt-5 mx-lg-5">
        <div className="alert" role="alert"></div>
        <div className="row">
          <div className="col-md-10 mb-3">
            <div className="card card-cascade narrower">
              <div
                className="view view-cascade gradient-card-header z-depth-2 topm rounded narrower p-1 py-0 mx-4  d-flex justify-content-between align-items-center"
                style={{ width: "24%" }}
              >
                <div className="mx-3 cardtab text-white">Search Students</div>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-8">
                        <p className="text-muted" style={{ fontSize: 12 }}>
                          Choose to search by
                        </p>
                        <div class="form-check pb-2 pt-0 mt-0">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="category"
                            value={"admission_number"}
                            onChange={(e) => searchSelector(e)}
                            defaultChecked
                          />
                          <label class="form-check-label">Admission No.</label>
                        </div>

                        <input
                          type="text"
                          id="id"
                          className="form-control"
                          placeholder={
                            searchby === "admission_number"
                              ? "Enter admission number"
                              : "Enter roll no"
                          }
                          onChange={(e) => formChangeHandler(e)}
                        />
                      </div>

                      <div className="col-md-4 mt-2 pt-2 ">
                        <button
                          className="btn btn-success btn-block pt-2 mt-5"
                          style={{ borderRadius: 25, fontSize: 14, padding: 8 }}
                          onClick={(e) => handleStudentSearch(e)}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-6 pt-4 mt-1 pb-3"
                    style={{ marginTop: -28 }}
                  >
                    <p
                      className="text-muted text-left"
                      style={{ fontSize: 12 }}
                    >
                      Filters to get Student list
                    </p>
                    <div className="row ">
                      <div className="col-md-6">
                        <select
                          name="category"
                          class="form-control form-control-sm"
                          onChange={(e) => handleClassFilter(e)}
                        >
                          <option selected disabled>
                            Category
                          </option>
                          {schoolInfo.hasOwnProperty("student_categories") ? (
                            schoolInfo.student_categories.map((cat) => (
                              <option value={cat.category}>
                                {cat.category}
                              </option>
                            ))
                          ) : (
                            <option>Categories not found</option>
                          )}
                        </select>
                      </div>

                      <div className="col-md-6">
                        <select
                          name="class"
                          class="form-control form-control-sm"
                          onChange={(e) => handleClassFilter(e)}
                        >
                          <option selected disabled>
                            Select Class
                          </option>
                          {schoolInfo.hasOwnProperty("classes") ? (
                            schoolInfo.classes.map((clss, i) => (
                              <option value={clss.class_name}>
                                {clss.class_name}
                              </option>
                            ))
                          ) : (
                            <option selected disabled>
                              No Classes Found
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-12 mb-5">
            <div className="" style={{ height: 310 }}>
              <div className="">
                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead className="thead">
                      <tr>
                        <th className="">Roll No</th>
                        <th className="">Admission No</th>
                        <th className="">Classs</th>
                        <th className="">Student Name</th>
                        <th className="">Father's Name</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataLoaded ? (
                        studentList.map((student) => (
                          <>
                            <tr>
                              <td>{student.s_roll}</td>
                              <td>{student.s_admission}</td>
                              <td>{student.s_class}</td>
                              <td className="capi">{student.s_name}</td>
                              <td className="capi">{student.sf_name}</td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Link
                                  to={`/app/student-details?referrer=student-list&s_id=${student.s_id}`}
                                  className="badge badge-pill badge-primary texSize"
                                >
                                  view Detail
                                </Link>
                                <Link
                                  to={`/app/student-payments?referrer=student-list&s_id=${student.s_id}`}
                                  className="badge badge-pill badge-success texSize"
                                  style={{ marginLeft: 5 }}
                                >
                                  Payments
                                </Link>
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <tr>No data loaded</tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
