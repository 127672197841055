import { SET_STUDENT_LIST, DATA_LOADED, SET_STUDENT_DATA } from "./actions";
const initialState = {
  studentsList: [],
  dataLoaded: false,
  studentData: {},
};

export const studentReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT_LIST:
      return {
        ...prevState,
        studentsList: action.payload,
      };
    case DATA_LOADED:
      return {
        ...prevState,
        dataLoaded: action.payload,
      };
    case SET_STUDENT_DATA:
      return {
        ...prevState,
        studentData: action.payload,
      };
    default:
      return prevState;
  }
};
