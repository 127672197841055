import React from "react";
import { useSelector } from "react-redux";
import {Link } from "react-router-dom";

export default function HeaderBarMenu(props) {
  const schoolInfo = useSelector((s) => s.schoolData.schoolInfo);
  
  const handleLogout = () => {
    props.functions.logout();
  };

  return (
    <React.Fragment>
      <header>
        <nav className="navbar fixed-top navbar-expand-sm navbar-light white scrolling-navbar">
          <div className="container-fluid">
            <a className="navbar-brand waves-effect">
              <img
                src={"../../assets/img/headlogo.png"}
                style={{ marginTop: -10 }}
                height="35"
                width="220"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item"></li>
                <li className="nav-item">
                  <a
                    onclick=""
                    className=" btn-outline-green rounded btn-sm px-2 waves-effect mr-2"
                    data-toggle="modal"
                    data-target="#genReports"
                    title="Payment Reports"
                  >
                    <i className="fa fa-file-excel-o"> Pay Reports</i>
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    to="/app/due-reports?r_id=1"
                    className=" btn-outline-red rounded btn-sm px-2 waves-effect mr-2"
                    title=" Due Reports"
                  >
                    <i className="fa fa-file-excel-o"> Due Reports</i>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav nav-flex-icons">
                <li className="nav-item"></li>
                <li className="nav-item"></li>
                <li className="nav-item">
                  <button
                    onClick={() => handleLogout()}
                    className="btn-outline-blue rounded btn-sm px-2 waves-effect"
                  >
                    <i className="fa fa-sign-out mr-2"> Logout</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}
