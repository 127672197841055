export const SET_SCHOOL_INFO = "SET_SCHOOL_INFO";
export const DATA_LOADED = "DATA_LOADED";
export const SET_DEFAULT_ACADEMIC = "SET_DEFAULT_ACADEMIC";
export const RESET_SCHOOL_DATA = "RESET_SCHOOL_DATA";
export const SET_FEE_STRUCTURES = "SET_FEE_STRUCTURES";


export const set_school_info = (schoolInfo) => {
  return {
    type: SET_SCHOOL_INFO,
    payload: schoolInfo,
  };
};

export const set_data_loaded = (state) => {
  return {
    type: DATA_LOADED,
    payload: state,
  };
};

export const set_default_academic = (state) => {
  return {
    type: SET_DEFAULT_ACADEMIC,
    payload: state,
  };
};

export const reset_school_data = () => {
  return {
    type: RESET_SCHOOL_DATA,
  };
};

export const set_fee_structures = (feeStructure) => {
  return {
    type: SET_FEE_STRUCTURES,
    payload: feeStructure,
  };
};
